import { SharedHttpServiceProvider } from '@/shared/api/SharedHttpServiceProvider';
import type { IHttpClient } from '@/shared/api/clients/HttpClient';
import { HttpAxiosClient } from '@/shared/api/clients/HttpAxiosClient';

let httpClientInstance: IHttpClient;
let httpServiceProvider: SharedHttpServiceProvider;

export const useSharedApi = (): SharedHttpServiceProvider => {
  if (!httpClientInstance) httpClientInstance = new HttpAxiosClient();

  httpServiceProvider = new SharedHttpServiceProvider(httpClientInstance);

  return httpServiceProvider;
};
