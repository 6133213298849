export enum TButtonType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}

export enum TButtonSize {
  X_SMALL = 'x-small',
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
}

export enum TButtonSupport {
  ERFOLG = 'erfolg',
  INFORMATION = 'information',
  WARNUNG = 'warnung',
  FEHLER = 'fehler',
}

export enum TButtonWidth {
  MIN = 'min',
  MEDIUM = 'medium',
  MAX = 'max',
}

export enum TButtonIconPosition {
  LINKS = 'links',
  RECHTS = 'rechts',
}
