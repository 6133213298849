import conf from '@/shared/config/useConfig';
import { buildUrlForServiceName } from './url-retriever';

const ASSET_ROOT_CONTEXT = buildUrlForServiceName(conf.partnerserviceName);

export default {
  getAssetContext(): string {
    return `${ASSET_ROOT_CONTEXT}/partnerservice`;
  },
};
