/* eslint-disable @typescript-eslint/no-use-before-define */
import { addClass, removeClass, removeElement } from './dom';
import { queue } from './queue';
import { barPositionCSS, clamp, getPositioningCSS, toBarPerc } from './utilities';
import setCSS from './css';

// Muss in Zukunft mal neu gebaut werden bzw. analysiert so das wir diese Komponente wirklich verstehen.

/**
 * The current progress number from 0...1, or `null` if not started.
 * @type {number | null}
 */
let status: number | null = null;

/**
 * Returns the current percentage.
 * @return {number | null}
 */
function getPercent() {
  return status;
}

/**
 * (Internal) sets the percent value.
 * This is only used in tests. Please use `set()` instead.
 * @param {number} value
 */
function setPercent(value: number) {
  status = value;
}

/**
 * @property {string=} margin-left
 * @property {string=} transition
 * @property {string=} transform
 */
const Settings = {
  minimum: 0.08,
  easing: 'linear',
  positionUsing: '',
  speed: 500,
  trickle: false,
  trickleSpeed: 200,
  showSpinner: false,
  barSelector: '[role="bar"]',
  spinnerSelector: '[role="spinner"]',
  parent: 'body',
  template:
    '<div class="bar" role="bar"><div class="peg"></div></div><div class="spinner" role="spinner"><div class="spinner-icon"></div></div>',
};

/**
 * Default export for CommonJS and for chaining
 */
const ProgressBar = {
  configure,
  done,
  getPercent,
  inc,
  isRendered,
  isStarted,
  remove,
  render,
  set,
  settings: Settings,
  start,
  trickle: inc,
};

/**
 * Updates configuration.
 *
 *     ProgressBar.configure({
 *       minimum: 0.1
 *     });
 *
 * @param {Partial<typeof Settings>} options
 */
// @ts-expect-error Typing unklar
function configure(options) {
  Object.keys(options).forEach((key) => {
    const value = options[key];
    if (value !== undefined && options.prototype.hasOwnProperty.call(options, key)) {
      // @ts-expect-error Typing unklar
      Settings[key] = value;
    }
  });

  return ProgressBar;
}

/**
 * Sets the progress bar status, where `n` is a number from `0.0` to `1.0`.
 * @param {number} n
 * @example
 *     ProgressBar.set(0.4);
 *     ProgressBar.set(1.0);
 */
// @ts-expect-error Typing unklar
function set(n) {
  const started = isStarted();

  const clampedN = clamp(n, Settings.minimum, 1);
  setPercent(clampedN === 1 ? null : clampedN);

  const progress = render(!started);
  const bar = progress.querySelector(Settings.barSelector);
  const { speed } = Settings;
  const ease = Settings.easing;

  // progress.offsetWidth; /** Repaint */

  queue((next: () => unknown) => {
    // Add transition
    const css = barPositionCSS(
      Settings.positionUsing || getPositioningCSS(),
      clampedN,
      speed,
      ease,
    );

    setCSS(bar, css);

    if (clampedN === 1) {
      // Fade out
      setCSS(progress, {
        transition: 'none',
        opacity: 1,
      });

      // progress.offsetWidth; /** Repaint */

      setTimeout(() => {
        setCSS(progress, {
          transition: `all ${speed}ms linear`,
          opacity: 0,
        });
        setTimeout(() => {
          remove();
          next();
        }, speed);
      }, speed);
    } else {
      setTimeout(next, speed);
    }
  });

  return ProgressBar;
}

function isStarted() {
  return typeof getPercent() === 'number';
}

/**
 * Shows the progress bar.
 * This is the same as setting the status to 0%, except that it doesn't go backwards.
 *
 * @example
 *     ProgressBar.start();
 */
function start() {
  disableInput();

  if (!getPercent()) {
    set(0);
  }

  const work = () => {
    setTimeout(() => {
      if (!getPercent()) {
        return;
      }
      inc(Math.random());
      work();
    }, Settings.trickleSpeed);
  };

  if (Settings.trickle) {
    work();
  }

  return ProgressBar;
}

/**
 * Hides the progress bar.
 * This is the *sort of* the same as setting the status to 100%, with the
 * difference being `done()` makes some placebo effect of some realistic motion.
 *
 *     ProgressBar.done();
 *
 * If `true` is passed, it will show the progress bar even if its hidden.
 *
 *     ProgressBar.done(true);
 *
 * @param {boolean | null | void} force
 */
// @ts-expect-error Typing unklar
function done(force) {
  if (!force && !getPercent()) {
    enableInput();
    return ProgressBar;
  }

  inc(0.3 + 0.5 * Math.random());
  set(1);

  enableInput();

  return ProgressBar;
}

/**
 * Increments by a random amount.
 *
 * @param {number} amount
 * @return {ProgressBar}
 */
// @ts-expect-error Typing unklar
function inc(amount) {
  let n = getPercent();
  let amountCopy = amount;

  if (!n) {
    return start();
  }
  if (n > 1) {
    return ProgressBar;
  }
  if (typeof amountCopy !== 'number') {
    if (n >= 0 && n < 0.2) {
      amountCopy = 0.1;
    } else if (n >= 0.2 && n < 0.5) {
      amountCopy = 0.04;
    } else if (n >= 0.5 && n < 0.8) {
      amountCopy = 0.02;
    } else if (n >= 0.8 && n < 0.99) {
      amountCopy = 0.005;
    } else {
      amountCopy = 0;
    }
  }

  n = clamp(n + amountCopy, 0, 0.994);
  return set(n);
}

/**
 * Returns the parent node as an HTMLElement.
 * @return {HTMLElement}
 */
function getParentElement() {
  const parent = document.querySelector(Settings.parent);
  if (!parent) {
    throw new Error(`ProgressBar: Invalid parent '${Settings.parent}'`);
  }
  return parent;
}

/**
 * (Internal) renders the progress bar markup based on the `template`
 * setting.
 *
 * @param {boolean=} fromStart If true, then it will reset to 0% before starting
 */
// @ts-expect-error Typing unklar
function render(fromStart) {
  if (isRendered()) {
    return document.getElementById('progressbar');
  }

  addClass(document.documentElement, 'progressbar-busy');

  const progress = document.createElement('div');
  progress.id = 'progressbar';
  progress.innerHTML = Settings.template;

  /** @type HTMLElement | null */
  const bar = progress.querySelector(Settings.barSelector);
  if (!bar) {
    throw new Error(`ProgressBar: No bar found for '${Settings.barSelector}'`);
  }

  const perc = fromStart ? '-100' : toBarPerc(getPercent() || 0);

  const parent: Element = getParentElement();

  let spinner: HTMLInputElement | null = null;

  setCSS(bar, {
    transition: 'all 0 linear',
    transform: `translate3d(${perc}%,0,0)`,
  });

  if (!Settings.showSpinner) {
    spinner = progress.querySelector(Settings.spinnerSelector);
    if (spinner !== undefined) {
      removeElement(spinner);
    }
  }

  if (parent !== document.body) {
    addClass(parent as HTMLElement, 'progressbar-custom-parent');
  }

  parent.appendChild(progress);
  return progress;
}

/**
 * Removes the element. Opposite of render().
 */
function remove() {
  removeClass(document.documentElement, 'progressbar-busy');
  removeClass(document.querySelector(Settings.parent), 'progressbar-custom-parent');

  const progress = document.getElementById('progressbar');
  if (progress !== undefined) {
    removeElement(progress);
  }
}

/**
 * Checks if the progress bar is rendered.
 */
function isRendered() {
  return !!document.getElementById('progressbar');
}

/**
 * Disables user input.
 */
function disableInput() {
  document.body.style.pointerEvents = 'none';
  return document.body.style.pointerEvents;
}

/**
 * Enables user input.
 */
function enableInput() {
  document.body.style.pointerEvents = 'auto';
  return document.body.style.pointerEvents;
}

/**
 * Export for ESM (ES Modules)
 */
export {
  configure,
  done,
  getPercent,
  inc as trickle,
  inc,
  isRendered,
  isStarted,
  remove,
  render,
  set,
  setPercent as _setPercent,
  Settings as settings,
  start,
};

export default ProgressBar;
