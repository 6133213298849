import { HttpService } from '@/shared/api/services/HttpService';
import { buildUrlForServiceName } from '@/connection/url-retriever';
import conf from '@/shared/config/useConfig';
import type { Projekt } from '@/types/Projekt';

export class SharedProjektService extends HttpService {
  protected projektServiceBaseUrl = `${buildUrlForServiceName(
    conf.projektserviceName,
  )}/buergschaftservice/api/private/v1/projekte`;

  async holeProjektZuBuergschaft(buergschaftId: number): Promise<Projekt> {
    return this.httpClient.get(`${this.projektServiceBaseUrl}/buergschaften/${buergschaftId}`);
  }
}
