import { computed, ref } from 'vue';

import type { PlattformHinweis } from '@/shared/PlattformHinweise/types';
import { useSharedApi } from '@/shared/api/useSharedApi';

const sharedApi = useSharedApi();

export const usePlattformHinweise = () => {
  const plattformHinweis = ref<PlattformHinweis | null>(null);
  const isLoading = ref(true);
  const nutzerHatBannerGesehen = ref({
    id: NaN,
    gesehen: false,
  });

  const zeigePlattformHinweis = computed(() => {
    if (isLoading.value || plattformHinweis.value === null) {
      return false;
    }

    return (
      nutzerHatBannerGesehen.value.id !== plattformHinweis.value.id ||
      !nutzerHatBannerGesehen.value.gesehen
    );
  });

  const ladePlattformHinweis = async () => {
    try {
      const hinweis = await sharedApi.sharedPlattformHinweis.holePlattformHinweis();
      const now = new Date();
      if (now >= hinweis.startZeit && (now < hinweis.endZeit || hinweis.endZeit === null)) {
        plattformHinweis.value = hinweis;
      } else {
        plattformHinweis.value = null;
      }
    } catch {
      plattformHinweis.value = null;
    } finally {
      isLoading.value = false;
    }
  };

  const markierePlattformHinweisGesehen = async () => {
    if (plattformHinweis.value !== null) {
      nutzerHatBannerGesehen.value.id = plattformHinweis.value.id;
      nutzerHatBannerGesehen.value.gesehen = true;

      await sharedApi.sharedPlattformHinweis.markierePlattformHinweisGelesen(
        plattformHinweis.value.id,
      );
    }
  };

  return {
    plattformHinweis,
    nutzerHatBannerGesehen,
    zeigePlattformHinweis,

    markierePlattformHinweisGesehen,
    ladePlattformHinweis,
  };
};
