import {
  createEmptyRechteStorage,
  isRechteStorageVorhanden,
  speichereRechteStorage,
} from '@/persistence/RechteStorage';
import partnerserviceApi from '@/connection/partnerservice-api';
import type { RouteLocationNormalized } from 'vue-router';
import { ladeNavigationStorage, speichereNavigationStorage } from '@/persistence/NavigationStorage';

/**
 * Hole eigene Rechte, falls nicht im RechteStorage vorhanden.
 * Falls der PartnerService kaputt ist, bleibt der RechteStorage leer (isRechteStorageVorhanden() liefert false) und
 * es wird beim nächsten Routing erneut probiert, die Rechte zu laden, bis es evtl. mal klappt
 */
export async function ladeRechteAusPartnerServiceInDenRechteStorage() {
  if (!isRechteStorageVorhanden()) {
    const partnerResponseEigene = await partnerserviceApi.holeEigeneRechte();
    const rechteStorage = createEmptyRechteStorage();
    rechteStorage.eigeneRechte = partnerResponseEigene.data;
    speichereRechteStorage(rechteStorage);
  }
}

/**
 * Speichert Route im alten Navigation Storage
 * @param to
 * @param from
 * @deprecated In Zukunft wollen wir den Pinia Store nutzen
 */
export function setRouteNamen(to: RouteLocationNormalized, from: RouteLocationNormalized) {
  const navigationStorage = ladeNavigationStorage();
  navigationStorage.routeNamen = [to.name.toString(), from?.name?.toString()];
  speichereNavigationStorage(navigationStorage);
}
