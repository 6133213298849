export enum TBadgeVarianten {
  // Counter Varianten
  COUNTER_SELECTED = 'counter-selected',
  COUNTER_UNSELECTED = 'counter-unselected',

  // Varianten für Projektstatus
  PROJEKT_STATUS_UMSETZUNG = 'projekt-status-umsetzung',
  PROJEKT_STATUS_GEWAEHR = 'projekt-status-gewaehr',
  PROJEKT_STATUS_ABGESCHLOSSEN = 'projekt-status-abgeschlossen',
  PROJEKT_STATUS_ABSCHLUSS = 'projekt-status-abschluss',
  PROJEKT_STATUS_PLANUNG = 'projekt-status-planung',

  // Variante Feature Status
  FEATURE_NEU = 'feature-neu',
  FEATURE_BALD = 'feature-bald',
  FEATURE_PREMIUM = 'feature-premium',
  FEATURE_BETA = 'feature-beta',

  // Nutzer
  NUTZER_AKTIV = 'nutzer-aktiv',
  NUTZER_INAKTIV = 'nutzer-inaktiv',

  // Verträge
  VERTRAG_IN_PRUEFUNG = 'vertrag-in-pruefung',
  VERTRAG_AKTIV = 'vertrag-aktiv',
  VERTRAG_ABGELEHNT = 'vertrag-abgelehnt',

  // GlobalBanner / Ribbon
  RIBBON_WICHTIG = 'ribbon-wichtig',

  // Bürgschaft
  DIGITAL_SIEGEL = 'digital-siegel',

  // Bürgschaftstext-Verifizierung
  TEXT_VERIFIZIERT = 'text-verifiziert',
  TEXT_ABWEICHEND = 'text-abweichend',
  TEXT_VERSION_ALT = 'text-version-alt',
  TEXT_UNBEKANNT = 'text-unbekannt',
}

export enum TBadgeSize {
  SMALL = 'small',
  MEDIUM = 'medium',
  BIG = 'big',
}

export const TBadgeVariantenDefaultText = Object.freeze({
  // Varianten für Projektstatus
  [TBadgeVarianten.PROJEKT_STATUS_UMSETZUNG]: 'Umsetzung',
  [TBadgeVarianten.PROJEKT_STATUS_GEWAEHR]: 'Gewährleistung',
  [TBadgeVarianten.PROJEKT_STATUS_ABGESCHLOSSEN]: 'Abgeschlossen',
  [TBadgeVarianten.PROJEKT_STATUS_ABSCHLUSS]: 'Abschluss',
  [TBadgeVarianten.PROJEKT_STATUS_PLANUNG]: 'Planung',
  // Variante Feature Status
  [TBadgeVarianten.FEATURE_NEU]: 'Neu',
  [TBadgeVarianten.FEATURE_BALD]: 'Bald',
  [TBadgeVarianten.FEATURE_PREMIUM]: 'Premium',
  [TBadgeVarianten.FEATURE_BETA]: 'Beta',
  // Nutzer
  [TBadgeVarianten.NUTZER_AKTIV]: 'Aktiv',
  [TBadgeVarianten.NUTZER_INAKTIV]: 'Inaktiv',
  // Verträge
  [TBadgeVarianten.VERTRAG_IN_PRUEFUNG]: 'In Prüfung',
  [TBadgeVarianten.VERTRAG_AKTIV]: 'Aktiv',
  [TBadgeVarianten.VERTRAG_ABGELEHNT]: 'Abgelehnt',
  // GlobalBanner / Ribbon
  [TBadgeVarianten.RIBBON_WICHTIG]: 'Wichtig',
  // Bürgschaft
  [TBadgeVarianten.DIGITAL_SIEGEL]: 'Digitale Bürgschaft',
  // Bürgschaftstext-Verifizierung
  [TBadgeVarianten.TEXT_VERIFIZIERT]: 'Ausgestellt mit Ihrem Text',
  [TBadgeVarianten.TEXT_ABWEICHEND]: 'Abweichender Text',
  [TBadgeVarianten.TEXT_VERSION_ALT]: 'Abweichende Version',
  [TBadgeVarianten.TEXT_UNBEKANNT]: 'Textherkunft unbekannt',
});
