import { format, parse } from 'date-fns';

export const parseDateString = (dateString: string, dateStringFormat = 'dd.MM.yyyy') => {
  const refDate = new Date();
  const [, , year] = dateString.split('.');

  if (year?.length === 2 && dateStringFormat === 'dd.MM.yyyy') {
    return parse(dateString, 'dd.MM.yy', refDate);
  }
  return parse(dateString, dateStringFormat, refDate);
};

export const formatiereDateString = (date: Date, datumsFormat = 'yyyy-MM-dd') =>
  format(date, datumsFormat);

export const zuDeutschemDatumString = (date: Date) => formatiereDateString(date, 'dd.MM.yyyy');
