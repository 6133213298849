import type { AxiosError, AxiosInstance, Method, RawAxiosRequestConfig } from 'axios';
import axios from 'axios';
import { buildSelfUrl } from '@/connection/url-retriever';
import {
  handleInvalidToken,
  resetSessionTimer,
  setToken,
} from '@/shared/api/clients/utilities/authorization';
import {
  errorHideProgressBar,
  hideProgressBar,
  showProgressBar,
} from '@/shared/api/clients/utilities/progressBar';
import type { ExtendedInternalAxiosRequestConfig } from '@/shared/api/clients/types';
import type { IHttpClient, IHttpRequestConfig } from './HttpClient';

const TIMEOUT_DEFAULT = 60_000;

export class HttpAxiosClient implements IHttpClient {
  protected axios: AxiosInstance;

  constructor() {
    const config: RawAxiosRequestConfig = {
      headers: {
        'Access-Control-Allow-Origin': buildSelfUrl(),
        'Content-Type': 'application/json',
      },
      timeout: TIMEOUT_DEFAULT,
    };

    this.axios = axios.create(config);

    this.axios.interceptors.request.use(
      (response) => {
        setToken(response);
        showProgressBar();
        return response;
      },
      async (error) => {
        errorHideProgressBar();
        return Promise.reject(error);
      },
    );

    this.axios.interceptors.response.use(
      (response) => {
        hideProgressBar();
        resetSessionTimer();
        return response;
      },
      (error: AxiosError) => this.onResponseError(error, this.axios),
    );
  }

  protected onResponseError(error: AxiosError, axiosInstance: AxiosInstance): unknown {
    errorHideProgressBar();
    const originalRequest = error.config as ExtendedInternalAxiosRequestConfig;
    // eslint-disable-next-line no-underscore-dangle
    if (error?.response?.status === 401 && !originalRequest._retry) {
      // Wir gehen davon aus, dass der 401 von einem abgelaufenen Token herrührt, daher versuchen wir es zu refreshen
      return handleInvalidToken(originalRequest, axiosInstance);
    }
    return Promise.reject(error);
  }

  protected async doRequest<T>(
    httpMethod: Method,
    url: string,
    config?: IHttpRequestConfig,
  ): Promise<T> {
    const requestConfig: RawAxiosRequestConfig = config ? this.buildAxiosConfig(config) : {};
    const request = this.axios.request<T>({
      ...requestConfig,
      method: httpMethod,
      url,
    });
    const response = await request;
    return response.data;
  }

  protected buildAxiosConfig(config: IHttpRequestConfig): RawAxiosRequestConfig {
    return {
      params: config.params,
      data: config.data,
      ...config.config,
    };
  }

  get<T>(url: string, config?: IHttpRequestConfig): Promise<T> {
    return this.doRequest('GET', url, config);
  }

  post<T>(url: string, config?: IHttpRequestConfig): Promise<T> {
    return this.doRequest('POST', url, config);
  }

  put<T>(url: string, config?: IHttpRequestConfig): Promise<T> {
    return this.doRequest('PUT', url, config);
  }

  patch<T>(url: string, config?: IHttpRequestConfig): Promise<T> {
    return this.doRequest('PATCH', url, config);
  }

  delete<T>(url: string, config?: IHttpRequestConfig): Promise<T> {
    return this.doRequest('DELETE', url, config);
  }
}
