import type { Config } from './types';

export const useConfig: Config = {
  allowedDomains: import.meta.env.VITE_ALLOWED_DOMAINS || undefined,
  stageName: import.meta.env.VITE_STAGE_NAME || undefined,
  trackingSiteId: import.meta.env.VITE_TRACKING_SITE_ID || '2',
  nutzerverwaltungName: import.meta.env.VITE_NUTZERVERWALTUNG_NAME || 'nutzerverwaltung:8080',
  partnerserviceName: import.meta.env.VITE_PARTNERSERVICE_NAME || 'localhost:8088',
  dokumentserviceName: import.meta.env.VITE_DOKUMENTSERVICE_NAME || 'localhost:8089',
  bürgschaftserviceName: import.meta.env.VITE_BUERGSCHAFTSERVICE_NAME || 'localhost:8090',
  aufgabenserviceName: import.meta.env.VITE_AUFGABENSERVICE_NAME || 'localhost:8092',
  virenscannerName: import.meta.env.VITE_VIRENSCANNER_NAME || 'localhost:8093',
  projektserviceName: import.meta.env.VITE_PROJEKTSERVICE_NAME || 'localhost:8090',
};

export default useConfig;
