import type { RouteLocationNormalized } from 'vue-router';

import { keycloakApi } from '@/connection/keycloak-api';
import { Domaene } from '@/types/Nutzer';
import { getRouteHierarchieAuftraggeber } from '@/auftraggeber/routeUtilities';
import { getRouteHierarchieBsp } from '@/bsp/routeUtilities';
import { getRouteHierarchieBuerge } from '@/buerge/routeUtilities';

export const ladeStartseiteWennKeineDomaenenRoute = (
  to: RouteLocationNormalized,
): string | null => {
  const { roles } = keycloakApi.getTokenParsed().realm_access;

  let zielRoute: null | string = null;

  Object.entries(Domaene).forEach(([domaeneKey, domaene]) => {
    if (roles.some((r) => r.startsWith(domaeneKey))) {
      if (to.path.startsWith(`/${domaene}/`)) {
        zielRoute = null;
      } else if (domaene === Domaene.AG) {
        zielRoute = getRouteHierarchieAuftraggeber();
      } else if (domaene === Domaene.BG) {
        zielRoute = getRouteHierarchieBuerge();
      } else if (domaene === Domaene.BSP) {
        zielRoute = getRouteHierarchieBsp();
      } else {
        zielRoute = domaene;
      }
    }
  });

  return zielRoute;
};
