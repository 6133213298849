import { defineStore } from 'pinia';

import { keycloakApi } from '@/connection/keycloak-api';
import type { Nutzer } from '@/types/Nutzer';
import { createEmptyNutzer } from '@/types/Nutzer';

interface State {
  nutzer: Nutzer;
  nutzerIdHash: string;
}

const urlSuchString = '/realms/bsp/account';

export const useNutzerStore = defineStore('NutzerStore', {
  state: (): State => ({
    nutzer: createEmptyNutzer(),
    nutzerIdHash: '',
  }),

  actions: {
    async ladeNutzer() {
      this.nutzer = await keycloakApi.ladeNutzer();
      this.nutzerIdHash = await this.nutzerIdAlsSha256();
    },

    logout() {
      keycloakApi.clearStorageAndLogout();
    },

    resetPassword() {
      if (this.nutzer.sso) {
        return;
      }

      let resetUrl = keycloakApi.createAccountUrl();
      const index = resetUrl.indexOf(urlSuchString) + urlSuchString.length;
      resetUrl = `${resetUrl.substring(0, index)}/password${resetUrl.substring(index)}`;
      window.location.href = resetUrl;
    },

    faktorEinstellen() {
      let url = keycloakApi.createAccountUrl();
      const index = url.indexOf(urlSuchString) + urlSuchString.length;
      url = `${url.substring(0, index)}/totp${url.substring(index)}`;
      window.location.href = url;
    },

    async nutzerIdAlsSha256() {
      const encoded = new TextEncoder().encode(this.nutzer.nutzerId);
      const hashArrayBuffer = await crypto.subtle.digest('SHA-256', encoded);
      const hashArray = Array.from(new Uint8Array(hashArrayBuffer));
      // convert bytes to hex string
      const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
      return hashHex.toUpperCase();
    },

    hatSichtberechtigungAufUnternehmen(unternehmenId: string): boolean {
      if (this.nutzer.unternehmenSichtbeschränkt) {
        return this.nutzer.sichtberechtigteUnternehmen.includes(unternehmenId);
      }
      return true;
    },

    hatSichtberechtigungNurAufUnternehmen(unternehmenId: string): boolean {
      if (this.nutzer.unternehmenSichtbeschränkt) {
        return (
          this.nutzer.sichtberechtigteUnternehmen.length === 1 &&
          this.nutzer.sichtberechtigteUnternehmen.includes(unternehmenId)
        );
      }
      return false;
    },
  },

  getters: {
    vollerName(state) {
      return `${state.nutzer.vorname} ${state.nutzer.nachname}`;
    },

    nutzerAlsAnsprechpartner(state) {
      return {
        vorname: state.nutzer.vorname,
        nachname: state.nutzer.nachname,
        email: state.nutzer.email,
        telefonnummer: state.nutzer.telefonnummer,
      };
    },

    hatNutzerKeineSichtbeschraenkungen(state) {
      return state.nutzer.unternehmenSichtbeschränkt === false;
    },

    getSichtberechtigteUnternehmen(state): string[] {
      return state.nutzer.sichtberechtigteUnternehmen;
    },
  },
});
