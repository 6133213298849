import { ANZEIGENAME, isRechtVorhanden, KATEGORIE } from '@/persistence/RechteStorage';

export function getRouteHierarchieBuerge() {
  if (isRechtVorhanden([KATEGORIE.AUFGABEN], ANZEIGENAME.ANZEIGEN)) {
    return 'aufgabenListeBürge';
  }
  if (isRechtVorhanden([KATEGORIE.BÜRGSCHAFTEN], ANZEIGENAME.ANZEIGEN)) {
    return 'bürgschaftenListeBürge';
  }
  if (isRechtVorhanden([KATEGORIE.BÜRGSCHAFTSTEXTE], ANZEIGENAME.ANZEIGEN)) {
    return 'bürgschaftstexteListeBürge';
  }
  if (isRechtVorhanden([KATEGORIE.VERTRÄGE], ANZEIGENAME.ANZEIGEN)) {
    return 'verträgeListeBürge';
  }
  if (isRechtVorhanden([KATEGORIE.ROLLEN, KATEGORIE.NUTZER], ANZEIGENAME.ANZEIGEN)) {
    return 'nutzerRollenListeBürge';
  }
  return 'kontoÜbersichtBürge';
}
