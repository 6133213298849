import type { RowObject } from '../components/datatable/types/RowObject';

export enum Gevo {
  /** Kautionsvertrag erstellt */
  VER_EST,
  /** Kautionsvertrag geprüft */
  VER_BSD,

  // Aus BürgschaftService

  /** Bürgschaft erstellen */
  BRG_ERST,
  /** Bürgschaft akzeptiert */
  BRG_AKZ,
  /** Bürgschaft abgelehnt */
  BRG_ABG,
  /** Bürgschaft korrigiert */
  BRG_KOR,
  /** Bürgschaft teilenthaftet */
  BRG_TEI,
  /** Bürgschaft zurückgegeben */
  BRG_RUE,
  /** Bürgschaft inanspruchgenommen */
  BRG_INA,

  /** Bürgschaftsauftrag eingegangen */
  AUF_EIN,

  // Aus DokumentService

  /** Bürgschaftsdokument manuell erstellt */
  DOK_MAN,
  /** Bürgschaftsdokument systematisch erstellt */
  DOK_SYS,
  /** Auftraggebertext geprüft */
  AGT_BSD,

  /** Bürgschaft eingegangen */
  BRG_EIN,
  /** Bürgschaft prüfen */
  BRG_PRF,
  /** Bürgschaft enthaften */
  BRG_ENT,
  /** Statuswechsel einer Bürgschaft eingegangen */
  BRG_STA,
}

export enum GevoKanal {
  SST = 'SST',
  EVS = 'EVS',
  MAIL = 'MAIL',
  PLA = 'PLA',
  PSST = 'PSST',
  KVER = 'KVER',
}

export const GevoKanalAnzeigeMap: Map<GevoKanal, string> = new Map([
  [GevoKanal.SST, 'Schnittstelle'],
  [GevoKanal.EVS, 'Eventsystem'],
  [GevoKanal.MAIL, 'E-Mail'],
  [GevoKanal.PLA, 'Plattform'],
  [GevoKanal.PSST, 'Plattform + Schnittstelle'],
  [GevoKanal.KVER, 'Keine Verwendung'],
]);

export const GevoKanalAnzeigeMapReverse: Map<string, GevoKanal> = new Map([
  ['Schnittstelle', GevoKanal.SST],
  ['Eventsystem', GevoKanal.EVS],
  ['E-Mail', GevoKanal.MAIL],
  ['Plattform', GevoKanal.PLA],
  ['Plattform + Schnittstelle', GevoKanal.PSST],
  ['Keine Verwendung', GevoKanal.KVER],
]);

export const GevoKanalAuftraggeberAnzeigeMap: Map<GevoKanal, string> = new Map([
  [GevoKanal.PLA, 'Plattform'],
  [GevoKanal.SST, 'Schnittstelle'],
  [GevoKanal.PSST, 'Plattform + Schnittstelle'],
  [GevoKanal.KVER, 'Keine Verwendung'],
]);

export interface Kommunikation {
  gevoDto: Gevo;
  gevoKanalDto: GevoKanal;
  gevoEmail?: string;
  gevoLabel: string;
  gevoKanalListeDto?: GevoKanal[];
}

export interface GevoRowItem extends RowObject {
  gevoDto: Gevo;
  gevoName: string;
  kommunikationskanal: string;
}

export interface GevoDaten {
  gevoDefaultEmail?: string;
  gevos: GevoRowItem[];
}
