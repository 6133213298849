import type { IConfig, IContext } from '@unleash/proxy-client-vue';
import { plugin, useFlag, useUnleashContext } from '@unleash/proxy-client-vue';

import { FeatureToggles } from './featureToggles';
import { PreisModell } from './preisModell';

interface ContextData extends Record<string, string> {
  preisModell?: string;
  unternehmenId?: string;
}

const config: IConfig = {
  url: `${import.meta.env.VITE_UNLEASH_URL}/api/frontend/`,
  clientKey: import.meta.env.VITE_UNLEASH_API_KEY,
  refreshInterval: 30,
  appName: 'frontend',
  context: {
    properties: {},
  },
};

export const useFeatureToggles = () => {
  let unleashContext: (context: IContext) => Promise<void>;

  const initContext = () => {
    unleashContext = useUnleashContext();
  };

  const setContextData = async (context: ContextData) => {
    await unleashContext({
      appName: 'frontend',
      properties: context,
    });
  };

  const useFeature = (feature: FeatureToggles) => useFlag(feature);

  return {
    plugin,
    config,

    FeatureToggles,
    PreisModell,
    useFeature,
    setContextData,
    initContext,
  };
};
