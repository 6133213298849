import { HttpService } from '@/shared/api/services/HttpService';
import { buildUrlForServiceName } from '@/connection/url-retriever';
import conf from '@/shared/config/useConfig';
import type {
  Bürgschaft as Buergschaft,
  BürgschaftHistorie as BuergschaftHistorie,
} from '@/types/Bürgschaft';
import type { BürgschaftFilter as BuergschaftFilter } from '@/connection/filter-helper/filter-helper-bürgschaften';
import { configForDownload, configForJsonPostDownload } from '@/connection/base';
import type { Buergschaftsauftrag } from '@/types/Buergschaftsauftrag';
import type { BuergschaftsauftragFilter } from '@/connection/filter-helper/filter-helper-buergschaftsauftraege';
import type { BürgschaftstemplateAllgemein as BuergschaftstemplateAllgemein } from '@/types/Dokument';

export class SharedBuergschaftService extends HttpService {
  protected buergschaftServiceBaseUrl = `${buildUrlForServiceName(
    conf.bürgschaftserviceName,
  )}/buergschaftservice/api/private/v2`;

  async holeBuergschaftDetails(id: number): Promise<Buergschaft> {
    return this.httpClient.get(`${this.buergschaftServiceBaseUrl}/buergschaften/${id}`);
  }

  async holeBuergschaftHistorie(id: string): Promise<BuergschaftHistorie[]> {
    return this.httpClient.get(`${this.buergschaftServiceBaseUrl}/buergschaft/${id}/historie`);
  }

  async holeAlleBuergschaftenCsv(buergschaftFilter: BuergschaftFilter): Promise<BlobPart> {
    /**
     * AG: Die Aufruferrolle wird in der Domäne AG mitgegeben, um zu unterscheiden, ob die
     * ausgestellten oder eingegangene Bürgschaften exportiert werden sollen
     * TL, BÜ: in den anderen beiden Domänen werden immer alle Bürgschaften exportiert
     */
    return this.httpClient.post(
      `${this.buergschaftServiceBaseUrl}/buergschaften/filterung/buergschaften.csv`,
      {
        data: buergschaftFilter,
        config: configForJsonPostDownload,
      },
    );
  }

  async holeBuergschaftCsv(buergschaftId: number | string): Promise<BlobPart> {
    return this.httpClient.get(
      `${this.buergschaftServiceBaseUrl}/buergschaften/${buergschaftId}/buergschaften.csv`,
      { config: configForDownload },
    );
  }

  async holeBuergschaftsauftragDetails(id: number): Promise<Buergschaftsauftrag> {
    return this.httpClient.get(`${this.buergschaftServiceBaseUrl}/buergschaftsauftraege/${id}`);
  }

  async holeAlleBuergschaftsauftraegeCsv(
    buergschaftsauftragFilter: BuergschaftsauftragFilter,
  ): Promise<BlobPart> {
    /**
     * AG: Die Aufruferrolle wird in der Domäne AG mitgegeben, um zu unterscheiden, ob die
     *         ausgestellten oder eingegangene Bürgschaften exportiert werden sollen
     * TL, BÜ: in den anderen beiden Domänen werden immer alle Bürgschaften exportiert
     */
    return this.httpClient.post(
      `${this.buergschaftServiceBaseUrl}/buergschaftsauftraege/filterung/buergschaftsauftraege.csv`,
      {
        data: buergschaftsauftragFilter,
        config: configForJsonPostDownload,
      },
    );
  }

  async holeBuergschaftsauftragCsv(auftragId: number): Promise<BlobPart> {
    return this.httpClient.get(
      `${this.buergschaftServiceBaseUrl}/buergschaftsauftraege/${auftragId}/buergschaftsauftraege.csv`,
      { config: configForDownload },
    );
  }

  async findeTemplatesNachId(id: string | number): Promise<BuergschaftstemplateAllgemein> {
    return this.httpClient.get(`${this.buergschaftServiceBaseUrl}/buergschaftstemplates/${id}`);
  }
}
