/**
 * (Internal) Removes an element from the DOM.
 *
 * @param {HTMLElement | null | void} element
 */
export function removeElement(element: HTMLElement | undefined | null) {
  if (element !== null && element !== undefined && element.parentElement) {
    element.parentNode.removeChild(element);
  }
}

/**
 * (Internal) Gets a space separated list of the class names on the element.
 * The list is wrapped with a single space on each end to facilitate finding
 * matches within the list.
 *
 * @param {HTMLElement | null | void} element
 */
// @ts-expect-error Typing unklar
export function classList(element) {
  return ` ${(element && element.className) || ''} `.replace(/\s+/gi, ' ');
}

/**
 * (Internal) Adds a class to an element.
 * @param {HTMLElement} element
 * @param {string} name
 */
export function addClass(element: HTMLElement, name: string) {
  if (!element.classList.contains(name)) {
    element.classList.add(name);
  }
}

/**
 * (Internal) Removes a class from an element.
 */
export function removeClass(element: HTMLElement, name: string) {
  if (element.classList.contains(name)) {
    element.classList.remove(name);
  }
}
