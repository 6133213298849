import { ANZEIGENAME, isRechtVorhanden, KATEGORIE } from '@/persistence/RechteStorage';

export function getRouteHierarchieBsp() {
  if (isRechtVorhanden([KATEGORIE.AUFGABEN], ANZEIGENAME.ANZEIGEN)) {
    return 'aufgabenListeTrustlog';
  }
  if (isRechtVorhanden([KATEGORIE.BÜRGSCHAFTEN], ANZEIGENAME.ANZEIGEN)) {
    return 'bürgschaftenListeTrustlog';
  }
  if (isRechtVorhanden([KATEGORIE.BÜRGSCHAFTSTEXTE], ANZEIGENAME.ANZEIGEN)) {
    return 'bürgschaftstexteListeTrustlog';
  }
  if (isRechtVorhanden([KATEGORIE.UNTERNEHMEN], ANZEIGENAME.ANZEIGEN)) {
    return 'stammdatenUnternehmenListeTrustlog';
  }

  return 'kontoÜbersichtTrustlog';
}
