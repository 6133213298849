<template>
  <span :class="classes">
    <TIcon v-if="icon !== undefined" :icon="icon" :size="iconSize" />
    {{ textToShow }}
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue';

import type { TBadgeVarianten } from '@/design-system/atoms/TBadge/types';
import { TBadgeSize, TBadgeVariantenDefaultText } from '@/design-system/atoms/TBadge/types';
import type { Icon } from '@/design-system/IconLib/types';
import TIcon from '@/design-system/atoms/TIcon/TIcon.vue';
import { TIconSize } from '@/design-system/atoms/TIcon/types';

interface Props {
  variante: TBadgeVarianten;
  text?: string;
  size?: TBadgeSize;
  icon?: Icon;
  useCurrentColor?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  text: '',
  size: TBadgeSize.MEDIUM,
  icon: undefined,
  useCurrentColor: true,
});

const textToShow = computed(() => {
  if (
    TBadgeVariantenDefaultText[props.variante as keyof typeof TBadgeVariantenDefaultText] !==
      undefined &&
    props.text === ''
  ) {
    return TBadgeVariantenDefaultText[props.variante as keyof typeof TBadgeVariantenDefaultText];
  }

  return props.text;
});

const iconSize = computed(() => {
  if (props.size !== TBadgeSize.BIG) {
    return TIconSize.XXX_SMALL;
  }

  return TIconSize.X_SMALL;
});

const classes = computed(() => ({
  't-badge': true,
  [`t-badge--${props.variante}`]: true,
  [`t-badge--${props.size}`]: true,
  't-badge--with-icon': props.icon !== undefined,
  't-badge--icon-use-current-color': props.useCurrentColor && props.icon !== undefined,
}));
</script>
