// Definierte WhiteLists für Zeichen

export const whiteListAlpha = /[äüöáàâéèêëíìîïóòôúùûçñśýßÄÖÜÁÀÂÉÈÊËÍÌÎÏÓÒÔÚÙÛÇÑŚÝa-zA-Z]/;

/**
 * Restriktive Whiteliste. Starke Einschränkung der Zeichenauswahl, keine ungewöhnlichen
 * Sonderzeichen.
 */
export const whiteListRestriktiv =
  /[äüöáàâéèêëíìîïóòôúùûçñśýßÄÖÜÁÀÂÉÈÊËÍÌÎÏÓÒÔÚÙÛÇÑŚÝa-zA-Z0-9\s'"_\-/]/;

/**
 * Whitelist für Namen. Noch immer starke Einschränkungen der Zeichenauswahl, aber erlaubt mehr
 * Sonderzeichen für z.B. Firmen Namen.
 */
export const whiteListNamen =
  /[äüöáàâéèêëíìîïóòôúùûçñśýßÄÖÜÁÀÂÉÈÊËÍÌÎÏÓÒÔÚÙÛÇÑŚÝa-zA-Z0-9\s'"_\-/()&:.,+]/;

/**
 * Whitelist für Ortsnamen. Noch immer starke Einschränkungen der Zeichenauswahl, aber erlaubt einige
 * Sonderzeichen.
 */
export const whiteListOrtNamen =
  /[äüöáàâéèêëíìîïóòôúùûçñśýßÄÖÜÁÀÂÉÈÊËÍÌÎÏÓÒÔÚÙÛÇÑŚÝa-zA-Z\s'"_\-/()&:.,+]/;

/**
 * Restriktive Whitelist für Namen. Stärkere Einschränkung als whiteListNamen, geeignet für Personen Namen.
 */
export const whiteListNamenRestriktiv =
  /[äüöáàâéèêëíìîïóòôúùûçñśýßÄÖÜÁÀÂÉÈÊËÍÌÎÏÓÒÔÚÙÛÇÑŚÝa-zA-Z\s]/;

/**
 * Whitelist für Freitext Felder. Geringe Einschränkung, kann theoretisch XSS erlauben.
 */
export const whiteListFreitext =
  /[äüöáàâéèêëíìîïóòôúùûçñśýßÄÖÜÁÀÂÉÈÊËÍÌÎÏÓÒÔÚÙÛÇÑŚÝa-zA-Z0-9\s'"_\-/()&:.,!?+=%#@€$§<>]/;

/**
 * Blacklist für gefährliche Sonderzeichen
 */
export const blackList = /(\${)|(#{)|(--)/g;

/**
 * Regex für validen Hexcode für Farben
 */
export const FarbeHexCode = /^#([0-9a-f]{3}){1,2}$/i;
