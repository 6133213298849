/** Ein Nutzer der BSP-Plattform (kann ein Mitarbeiter des Auftraggebers, des Bürgen oder der BSP GmbH sein; siehe Rolle) */
export interface Nutzer {
  nutzerId: string;
  erstelltAm: Date;
  organisation: string;
  initialen: string;
  vorname: string;
  nachname: string;
  telefonnummer: string;
  email: string;
  aktiv: boolean;
  locale: string;
  rolle: Rolle;
  sso: boolean;
  unternehmenSichtbeschränkt: boolean;
  erhaltNutzerEMails: boolean;
  sichtberechtigteUnternehmen: string[];
}

export interface Rolle {
  name: string;
  iconname: string;
  immutable: boolean;
  rechte: Recht[];
}

export interface Recht {
  name: string;
  kategorie: string;
  anzeigename: string;
  adminExklusiv: boolean;
  adminVerpflichtend: boolean;
  verpflichtend: boolean;
  compositeRechte: Recht[];
}

export function createEmptyRolle(): Rolle {
  return {
    name: '',
    iconname: '',
    immutable: false,
    rechte: [],
  };
}

/** Quasi-Konstruktor zur Erzeugung eines neuen Nutzer-Objekts */
export function createEmptyNutzer(): Nutzer {
  return {
    nutzerId: null,
    erstelltAm: null,
    organisation: '',
    initialen: '',
    vorname: '',
    nachname: '',
    telefonnummer: '',
    email: '',
    aktiv: false,
    locale: '',
    rolle: createEmptyRolle(),
    sso: false,
    unternehmenSichtbeschränkt: false,
    erhaltNutzerEMails: null,
    sichtberechtigteUnternehmen: [],
  };
}

/** Die Domäne eines Nutzers, die maßgeblich darüber entscheidet, welche Sichten der Nutzer hat (siehe auch Verzeichnisstruktur in src) */
export enum Domaene {
  AG = 'auftraggeber',
  BSP = 'bspGmbh',
  BG = 'buerge',
}

export enum Rollen {
  ADMINISTRATOR = 'Administrator:in',
  BASISNUTZER = 'Basisnutzer:in',
  SCHNITTSTELLENNUTZER = 'Schnittstellennutzer:in',
  TECHNISCHER_NUTZER = 'Technischer Nutzer',
}

export enum BlacklistRollen {
  ADMINISTRATOR = 'Admin',
  BASISNUTZER = 'Basisnutzer',
  SCHNITTSTELLENNUTZER = 'Schnittstellennutzer',
  TECHNISCHER_NUTZER = 'Technischer Nutzer',
}

/** Hilfsfunktionen zur Ermittlung der Berechtigungen der jeweiligen Sonderrollen */

export function isNutzerAdmin(nutzer: Nutzer): boolean {
  return nutzer.rolle.name === Rollen.ADMINISTRATOR;
}

export function isNutzerSchnittstellennutzer(nutzer: Nutzer): boolean {
  return nutzer.rolle.name === Rollen.SCHNITTSTELLENNUTZER;
}

export function isNutzerTechnischerNutzer(nutzer: Nutzer): boolean {
  return nutzer.rolle.name === Rollen.TECHNISCHER_NUTZER;
}

export function isNutzerBasisnutzer(nutzer: Nutzer): boolean {
  return nutzer.rolle.name === Rollen.BASISNUTZER;
}

export function isNutzerSsoNutzer(nutzer: Nutzer): boolean {
  return nutzer.sso;
}

/**
 * Nutzer mit folgenden Rollen können nicht bearbeitet werden (Stammdaten und Kontaktdaten des Nutzers):
 * Administrator:in, Schnittstellennutzer:in, Technischer Nutzer
 */
export function isNutzerBearbeitbar(nutzer: Nutzer): boolean {
  return !(
    isNutzerAdmin(nutzer) ||
    isNutzerSchnittstellennutzer(nutzer) ||
    isNutzerTechnischerNutzer(nutzer) ||
    isNutzerSsoNutzer(nutzer)
  );
}

/**
 * Nutzer mit folgenden Rollen kann nicht die Rolle entzogen werden (Nutzerrolle): Administrator:in
 * Nutzer mit folgenden Rollen kann die Rolle entzogen werden (Nutzerrolle): Schnittstellennutzer:in, Technischer Nutzer, SSO-Nutzer
 */
export function isNutzerRolleVeränderbar(nutzer: Nutzer): boolean {
  return !isNutzerAdmin(nutzer);
}

/**
 * Nutzer mit folgenden Rollen können nicht gelöscht werden: Administrator:in, Schnittstellennutzer:in, Technischer Nutzer
 */
export function isNutzerLöschbar(nutzer: Nutzer): boolean {
  return !(
    isNutzerAdmin(nutzer) ||
    isNutzerSchnittstellennutzer(nutzer) ||
    isNutzerTechnischerNutzer(nutzer)
  );
}

/** Status, die eine Nutzer durchläuft */
export enum Nutzerstatus {
  /** Ausstehend */
  AKT = 'AKT',

  /** Ungeprüft */
  INA = 'INA',
}

/** Map, die den Bürgschaftsstatus auf einen Text für die Anzeige abbildet - für Auftraggeber */
export const NutzerStatusAnzeigetextMap: Map<Nutzerstatus, string> = new Map([
  [Nutzerstatus.AKT, 'Aktiv'],
  [Nutzerstatus.INA, 'Inaktiv'],
]);

export interface BeziehungNutzerOrganisation {
  status: string;
}
