import { HttpService } from '@/shared/api/services/HttpService';
import { buildUrlForServiceName } from '@/connection/url-retriever';
import conf from '@/shared/config/useConfig';
import type { PlattformHinweis, PlattformHinweisDto } from '@/shared/PlattformHinweise/types';
import type { TBadgeVarianten } from '@/design-system/atoms/TBadge/types';
import type { TRibbonVariant } from '@/design-system/atoms/TRibbon/types';
import { configForJsonPost } from '@/connection/base';

export class SharedPlattformHinweisService extends HttpService {
  protected plattformHinweisServiceBaseUrl = `${buildUrlForServiceName(conf.aufgabenserviceName)}/aufgabenservice/api/v1/private/plattformhinweis`;

  async holePlattformHinweis(): Promise<PlattformHinweis> {
    return this.httpClient
      .get<PlattformHinweisDto>(`${this.plattformHinweisServiceBaseUrl}`)
      .then((res) => {
        return {
          ...res,
          startZeit: new Date(res.startZeit),
          endZeit: res.endZeit != null ? new Date(res.endZeit) : null,
          badge: (res.badge as TBadgeVarianten) ?? null,
          typ: res.typ as TRibbonVariant,
        } as PlattformHinweis;
      });
  }

  async markierePlattformHinweisGelesen(hinweisId: PlattformHinweisDto['id']): Promise<void> {
    return this.httpClient.put(`${this.plattformHinweisServiceBaseUrl}/geschlossen/${hinweisId}`, {
      config: configForJsonPost,
    });
  }
}
