<template>
  <span class="t-icon" :class="classes">
    <component :is="iconComponent" />
  </span>
</template>

<script setup lang="ts">
import { iconLib } from '@/design-system/IconLib/iconLib';
import type { Icon } from '@/design-system/IconLib/types';
import { TIconSize } from '@/design-system/atoms/TIcon/types';
import { computed } from 'vue';

interface Props {
  icon: Icon;
  size?: TIconSize;
}

defineOptions({
  name: 'TIcon',
});

const props = withDefaults(defineProps<Props>(), {
  size: TIconSize.SMALL,
});

const classes = computed(() => `t-icon--${props.size}`);

const iconComponent = computed(() => iconLib[props.icon] as unknown);
</script>
