import ProgressBar from '@/common/progressbar/progressbar';

export const showProgressBar = () => {
  if (!ProgressBar.isRendered()) {
    ProgressBar.start();
  } else {
    ProgressBar.render(false);
  }
};

export const hideProgressBar = () => {
  ProgressBar.done(false);
};

export const errorHideProgressBar = () => {
  ProgressBar.done(true);
};
