import type { RouteRecordRaw } from 'vue-router';
import { AuftraggeberRoutes } from '@/auftraggeber/routeHelpers';
import { ANZEIGENAME, KATEGORIE } from '@/persistence/RechteStorage';

// Lazy Loading (https://router.vuejs.org/guide/advanced/lazy-loading.html);
const BuergschaftDetails = () => import('./components/buergschaften/BuergschaftDetails.vue');
const AuftraggebertextDetails = () =>
  import('./components/buergschaftstexte/AuftraggebertextDetails.vue');
const NutzerUnternehmenAuswaehlen = () =>
  import('./components/nutzer/NutzerUnternehmenAuswaehlen.vue');

function createProjektRouteEntry(path: string, name: string, part: string) {
  return {
    path,
    name,
    props: true,
    redirect: { name: `${part}-${AuftraggeberRoutes.AG_PROJEKT_FORM_DETAILS}` },
    component: () => import('./components/projekte/views/form/ProjektFormBaseView.vue'),
    meta: {
      kategorie: [KATEGORIE.PROJEKTE],
      anzeigeName: ANZEIGENAME.VERWALTEN,
    },
    children: [
      {
        path: 'details',
        name: `${part}-${AuftraggeberRoutes.AG_PROJEKT_FORM_DETAILS}`,
        props: true,
        component: () => import('./components/projekte/views/form/ProjektFormDetailsView.vue'),
        meta: {
          kategorie: [KATEGORIE.PROJEKTE],
          anzeigeName: ANZEIGENAME.VERWALTEN,
        },
      },
      {
        path: 'verantwortliches-unternehmen-auswahl',
        name: `${part}-${AuftraggeberRoutes.AG_PROJEKT_FORM_VERANTWORTLICHES_UNTERNEHMEN}`,
        props: true,
        component: () =>
          import('./components/projekte/views/form/ProjektFormVerantwortlichesUnternehmenView.vue'),
        meta: {
          kategorie: [KATEGORIE.PROJEKTE],
          anzeigeName: ANZEIGENAME.VERWALTEN,
        },
      },
      {
        path: 'sichtberechtigung',
        name: `${part}-${AuftraggeberRoutes.AG_PROJEKT_FORM_SICHTBERECHTIGUNG}`,
        props: true,
        component: () =>
          import('./components/projekte/views/form/ProjektFormSichtberechtigungView.vue'),
        meta: {
          kategorie: [KATEGORIE.PROJEKTE],
          anzeigeName: ANZEIGENAME.VERWALTEN,
        },
      },
    ],
  };
}

const auftraggeberRoutes: RouteRecordRaw = {
  path: '/auftraggeber',
  component: () => import('./Auftraggeber.vue'),
  name: 'auftraggeber',
  children: [
    /** MITTEILUNGEN */
    {
      path: 'mitteilungen/liste',
      name: 'mitteilungenListeAuftraggeber',
      component: () => import('./components/mitteilungen/MitteilungenListe.vue'),
      meta: {
        kategorie: [KATEGORIE.AUFGABEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },

    {
      path: 'mitteilungen/details/:mitteilungId(\\d+)',
      name: 'mitteilungDetailsAuftraggeber',
      component: () => import('./components/mitteilungen/MitteilungDetails.vue'),
      props: true,
      meta: {
        kategorie: [KATEGORIE.AUFGABEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },

    /** AUFGABEN */
    {
      path: 'aufgaben/liste',
      name: AuftraggeberRoutes.AG_AUFGABEN_LISTE,
      component: () => import('./components/aufgaben/AufgabenListe.vue'),
      props: true,
      meta: {
        kategorie: [KATEGORIE.AUFGABEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },

    /** BÜRGSCHAFTEN */
    {
      path: 'buergschaften/liste',
      name: 'buergschaftenListeAuftraggeber',
      component: () => import('./components/buergschaften/BuergschaftenListe.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaften/details/:buergschaftId(\\d+)',
      name: 'buergschaftDetailsAuftraggeber',
      props: true,
      component: BuergschaftDetails,
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaften/abgetreten/:buergschaftId(\\d+)',
      name: 'buergschaftAbgetretenDetailsAuftraggeber',
      props: true,
      component: () => import('./components/buergschaften/views/BuergschaftAbtretungDetails.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaften/:buergschaftId(\\d+)/akzeptieren',
      name: 'buergschaftAkzeptierenAuftraggeber',
      props: {
        isAkzeptieren: true,
        contentKey: 'akzeptieren',
      },
      component: BuergschaftDetails,
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.PRÜFEN,
      },
    },
    {
      path: 'buergschaften/:buergschaftId(\\d+)/akzeptieren/erfolg',
      name: 'buergschaftAkzeptierenErfolgAuftraggeber',
      props: {
        isAkzeptierenErfolg: true,
        contentKey: 'akzeptierenErfolg',
        istBuergschaftProjektZugeordnet: false,
      },
      component: BuergschaftDetails,
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.PRÜFEN,
      },
    },
    {
      path: 'buergschaften/:buergschaftId(\\d+)/akzeptieren/erfolg',
      name: 'buergschaftProjektZugeordnetAkzeptierenErfolgAuftraggeber',
      props: {
        isAkzeptierenErfolg: true,
        contentKey: 'akzeptierenErfolg',
        istBuergschaftProjektZugeordnet: true,
      },
      component: BuergschaftDetails,
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.PRÜFEN,
      },
    },
    {
      path: 'buergschaften/:buergschaftId(\\d+)/ablehnen',
      name: 'buergschaftAblehnenAuftraggeber',
      props: true,
      component: () =>
        import('./components/buergschaften/aktionen/ablehnen/BuergschaftAblehnen.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.PRÜFEN,
      },
    },

    /** BÜRGSCHAFT-RÜCKFORDERN */
    {
      // Aktuell muss der Param buergschaftId heißen, da die Komponente, die das ganze Lädt,
      // das erwartet. Das kann angepasst werden, wenn BürgschaftÜbersicht zerschlagen wurde.
      path: 'buergschaften/rueckforderung/:buergschaftId(\\d+)',
      name: AuftraggeberRoutes.AG_RUECKFORDERUNG_BUERGSCHAFT_PRUEFEN_AUFGABE,
      props: true,
      component: () =>
        import(
          '@/auftraggeber/components/buergschaften/views/rueckforderungPruefen/RueckforderungPruefenBaseView.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
      redirect: { name: AuftraggeberRoutes.AG_RUECKFORDERUNG_BUERGSCHAFT_PRUEFEN_AUFGABE_PRUEFEN },
      children: [
        {
          path: 'details-pruefen',
          name: AuftraggeberRoutes.AG_RUECKFORDERUNG_BUERGSCHAFT_PRUEFEN_AUFGABE_PRUEFEN,
          component: () =>
            import(
              '@/auftraggeber/components/buergschaften/views/rueckforderungPruefen/RueckforderungPruefenDatenPruefen.vue'
            ),
        },
        {
          path: 'akzeptieren',
          name: AuftraggeberRoutes.AG_RUECKFORDERUNG_BUERGSCHAFT_PRUEFEN_AUFGABE_ENTSCHEIDUNG,
          component: () =>
            import(
              '@/auftraggeber/components/buergschaften/views/rueckforderungPruefen/RueckforderungPruefenEntscheidung.vue'
            ),
          beforeEnter: (to, from, next) => {
            const validSteps: string[] = [
              AuftraggeberRoutes.AG_RUECKFORDERUNG_BUERGSCHAFT_PRUEFEN_AUFGABE_PRUEFEN,
              AuftraggeberRoutes.AG_RUECKFORDERUNG_BUERGSCHAFT_PRUEFEN_AUFGABE_BESTAETIGEN,
            ];

            if (validSteps.includes(from.name.toString())) {
              return next();
            }
            return next({
              name: AuftraggeberRoutes.AG_RUECKFORDERUNG_BUERGSCHAFT_PRUEFEN_AUFGABE_BESTAETIGEN,
            });
          },
        },
        {
          path: 'bestaetigen',
          name: AuftraggeberRoutes.AG_RUECKFORDERUNG_BUERGSCHAFT_PRUEFEN_AUFGABE_BESTAETIGEN,
          component: () =>
            import(
              '@/auftraggeber/components/buergschaften/views/rueckforderungPruefen/RueckforderungPruefenBestaetigen.vue'
            ),
          beforeEnter: (to, from, next) => {
            if (
              from.name ===
              AuftraggeberRoutes.AG_RUECKFORDERUNG_BUERGSCHAFT_PRUEFEN_AUFGABE_ENTSCHEIDUNG
            ) {
              return next();
            }
            return next({
              name: AuftraggeberRoutes.AG_RUECKFORDERUNG_BUERGSCHAFT_PRUEFEN_AUFGABE_PRUEFEN,
            });
          },
        },
      ],
    },

    /** BÜRGSCHAFT-ABTRETEN */
    {
      path: 'buergschaften/:buergschaftId(\\d+)/abtreten',
      name: AuftraggeberRoutes.AG_BUERGSCHAFT_ABTRETEN,
      props: true,
      component: () =>
        import(
          '@/auftraggeber/components/buergschaften/views/abtreten/BuergschaftAbtretenBase.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.ABTRETEN,
      },
      redirect: { name: AuftraggeberRoutes.AG_BUERGSCHAFT_ABTRETEN_EMPFAENGER },
      children: [
        {
          path: 'empfaenger',
          name: AuftraggeberRoutes.AG_BUERGSCHAFT_ABTRETEN_EMPFAENGER,
          props: true,
          component: () =>
            import(
              '@/auftraggeber/components/buergschaften/views/abtreten/BuergschaftAbtretenEmpfaenger.vue'
            ),
        },
        {
          path: 'ansprechpartner',
          name: AuftraggeberRoutes.AG_BUERGSCHAFT_ABTRETEN_ANSPRECHPARTNER,
          props: true,
          component: () =>
            import(
              '@/auftraggeber/components/buergschaften/views/abtreten/BuergschaftAbtretenAnsprechpartner.vue'
            ),
          // Verweigere Einstieg mitten im Prozess
          // (Component Level Hooks funktionieren mit Script Setup in 2.7 noch nicht)
          beforeEnter: (to, from, next) => {
            const validSteps: string[] = [
              AuftraggeberRoutes.AG_BUERGSCHAFT_ABTRETEN_EMPFAENGER,
              AuftraggeberRoutes.AG_BUERGSCHAFT_ABTRETEN_PRUEFEN,
            ];

            if (validSteps.includes(from.name.toString())) {
              return next();
            }

            return next({ name: AuftraggeberRoutes.AG_BUERGSCHAFT_ABTRETEN_EMPFAENGER });
          },
        },
        {
          path: 'pruefen',
          name: AuftraggeberRoutes.AG_BUERGSCHAFT_ABTRETEN_PRUEFEN,
          props: true,
          component: () =>
            import(
              '@/auftraggeber/components/buergschaften/views/abtreten/BuergschaftAbtretenPruefen.vue'
            ),
          // Verweigere Einstieg mitten im Prozess
          // (Component Level Hooks funktionieren mit Script Setup in 2.7 noch nicht)
          beforeEnter: (to, from, next) => {
            const validSteps: string[] = [
              AuftraggeberRoutes.AG_BUERGSCHAFT_ABTRETEN_ANSPRECHPARTNER,
              AuftraggeberRoutes.AG_BUERGSCHAFT_ABTRETEN_BESTAETIGEN,
            ];

            if (validSteps.includes(from.name.toString())) {
              return next();
            }
            return next({ name: AuftraggeberRoutes.AG_BUERGSCHAFT_ABTRETEN_EMPFAENGER });
          },
        },
        {
          path: 'bestaetigen',
          name: AuftraggeberRoutes.AG_BUERGSCHAFT_ABTRETEN_BESTAETIGEN,
          props: true,
          component: () =>
            import(
              '@/auftraggeber/components/buergschaften/views/abtreten/BuergschaftAbtretenBestaetigen.vue'
            ),
          // Verweigere Einstieg mitten im Prozess
          // (Component Level Hooks funktionieren mit Script Setup in 2.7 noch nicht)
          beforeEnter: (to, from, next) => {
            if (AuftraggeberRoutes.AG_BUERGSCHAFT_ABTRETEN_PRUEFEN === from.name) {
              return next();
            }
            return next({ name: AuftraggeberRoutes.AG_BUERGSCHAFT_ABTRETEN_EMPFAENGER });
          },
        },
      ],
    },

    /** ABTRETUNG PRÜFEN */
    {
      // Aktuell muss der Param buergschaftId heißen, da die Komponente, die das ganze Lädt,
      // das erwartet. Das kann angepasst werden, wenn BürgschaftÜbersicht zerschlagen wurde.
      path: 'buergschaften/abtretung/:buergschaftId(\\d+)',
      name: AuftraggeberRoutes.AG_ABTRETUNG_PRUEFEN_AUFGABE,
      props: true,
      component: () =>
        import(
          '@/auftraggeber/components/buergschaften/views/abtretungPruefen/AbtretungPruefenBaseView.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
      redirect: { name: AuftraggeberRoutes.AG_ABTRETUNG_PRUEFEN_AUFGABE_PRUEFEN },
      children: [
        {
          path: 'details-pruefen',
          name: AuftraggeberRoutes.AG_ABTRETUNG_PRUEFEN_AUFGABE_PRUEFEN,
          component: () =>
            import(
              '@/auftraggeber/components/buergschaften/views/abtretungPruefen/AbtretungPruefenDatenPruefen.vue'
            ),
        },
        {
          path: 'akzeptieren',
          name: AuftraggeberRoutes.AG_ABTRETUNG_PRUEFEN_AUFGABE_ENTSCHEIDUNG,
          component: () =>
            import(
              '@/auftraggeber/components/buergschaften/views/abtretungPruefen/AbtretungPruefenEntscheidung.vue'
            ),
          beforeEnter: (to, from, next) => {
            const validSteps: string[] = [
              AuftraggeberRoutes.AG_ABTRETUNG_PRUEFEN_AUFGABE_PRUEFEN,
              AuftraggeberRoutes.AG_ABTRETUNG_PRUEFEN_AUFGABE_BESTAETIGEN,
            ];

            if (validSteps.includes(from.name.toString())) {
              return next();
            }
            return next({ name: AuftraggeberRoutes.AG_ABTRETUNG_PRUEFEN_AUFGABE_PRUEFEN });
          },
        },
        {
          path: 'bestaetigen',
          name: AuftraggeberRoutes.AG_ABTRETUNG_PRUEFEN_AUFGABE_BESTAETIGEN,
          component: () =>
            import(
              '@/auftraggeber/components/buergschaften/views/abtretungPruefen/AbtretungPruefenBestaetigen.vue'
            ),
          beforeEnter: (to, from, next) => {
            if (from.name === AuftraggeberRoutes.AG_ABTRETUNG_PRUEFEN_AUFGABE_ENTSCHEIDUNG) {
              return next();
            }
            return next({ name: AuftraggeberRoutes.AG_ABTRETUNG_PRUEFEN_AUFGABE_PRUEFEN });
          },
        },
      ],
    },

    /** BÜRGSCHAFTSANTRÄGE */

    /** Liste aller Bürgschaftsanträge */
    {
      path: 'buergschaftsauftraege/liste',
      name: 'buergschaftsauftraegeListeAuftraggeber',
      props: true,
      component: () => import('./components/buergschaftsauftraege/BuergschaftsauftragListe.vue'),
      meta: {
        kategorie: [KATEGORIE.ANTRÄGE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },

    /** Bürgschaftsantrag mit den Details anzeigen */
    {
      path: 'buergschaftsauftraege/details/:buergschaftsauftragId(\\d+)',
      name: 'buergschaftsauftragDetailsAuftraggeber',
      props: true,
      component: () => import('./components/buergschaftsauftraege/BuergschaftsauftragDetails.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    /** Bürgschaft beantragen - in der Rolle AN kann der Nutzer eine Bürgschaft beantragen */
    {
      path: 'buergschaftsauftraege/beantragen/auftragnehmerAuswaehlen',
      name: 'buergschaftsauftragAuftragnehmerAuswaehlen',
      component: () =>
        import(
          './components/buergschaftsauftraege/aktionen/beantragen/BuergschaftsauftragAuftragnehmerAuswaehlen.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.ANTRÄGE],
        anzeigeName: ANZEIGENAME.EINREICHEN,
      },
    },
    {
      path: 'buergschaftsauftraege/beantragen/auftraggeberAuswaehlen',
      name: 'buergschaftsauftragAuftraggeberAuswaehlen',
      component: () =>
        import(
          './components/buergschaftsauftraege/aktionen/beantragen/BuergschaftsauftragAuftraggeberAuswaehlen.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.ANTRÄGE],
        anzeigeName: ANZEIGENAME.EINREICHEN,
      },
    },
    {
      path: 'buergschaftsauftraege/beantragen/VertragAuswaehlen',
      name: 'buergschaftsauftragVertragAuswaehlen',
      component: () =>
        import(
          './components/buergschaftsauftraege/aktionen/beantragen/BuergschaftsauftragVertragAuswaehlen.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.ANTRÄGE],
        anzeigeName: ANZEIGENAME.EINREICHEN,
      },
    },
    {
      path: 'buergschaftsauftraege/beantragen/buergschaftsdatenErfassen',
      name: 'buergschaftsauftragBuergschaftsdatenErfassen',
      component: () =>
        import(
          './components/buergschaftsauftraege/aktionen/beantragen/BuergschaftsauftragBuergschaftsdatenErfassen.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.ANTRÄGE],
        anzeigeName: ANZEIGENAME.EINREICHEN,
      },
    },
    {
      path: 'buergschaftsauftraege/beantragen/versandwegAuswaehlen',
      name: 'buergschaftsauftragVersandwegAuswaehlen',
      component: () =>
        import(
          '@/auftraggeber/components/buergschaftsauftraege/aktionen/beantragen/BuergschaftsauftragVersandweg.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.ANTRÄGE],
        anzeigeName: ANZEIGENAME.EINREICHEN,
      },
    },
    {
      path: 'buergschaftsauftraege/beantragen/buergschaftsZusammenfassung',
      name: 'buergschaftsauftragZusammenfassung',
      component: () =>
        import(
          './components/buergschaftsauftraege/aktionen/beantragen/BuergschaftsauftragZusammenfassung.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.ANTRÄGE],
        anzeigeName: ANZEIGENAME.EINREICHEN,
      },
    },
    {
      path: 'buergschaftsauftraege/beantragen/buergschaftsvorlage',
      name: 'buergschaftsauftragVorlageAuswaehlen',
      component: () =>
        import(
          './components/buergschaftsauftraege/aktionen/beantragen/BuergschaftsauftragVorlageAuswaehlen.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.ANTRÄGE],
        anzeigeName: ANZEIGENAME.EINREICHEN,
      },
    },
    {
      path: 'buergschaftsauftraege/beantragen/:buergschaftsAuftragId(\\d+)/buergschaftsauftragErfolg',
      name: 'buergschaftsauftragErfolg',
      props: true,
      component: () =>
        import(
          './components/buergschaftsauftraege/aktionen/beantragen/BuergschaftsauftragErfolg.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.ANTRÄGE],
        anzeigeName: ANZEIGENAME.EINREICHEN,
      },
    },

    /** Bürgschaft anlegen - Möglichkeit für den Auftraggeber eine Altbürgschaft selbst anzulegen */
    {
      path: 'buergschaften/anlegen/beteiligungstypEntscheidung',
      name: 'bürgschaftAnlegenBeteiligungstypEntscheidung',
      component: () =>
        import('./components/buergschaften/aktionen/anlegen/BeteiligungstypEntscheidung.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.IMPORTIEREN,
      },
    },
    {
      path: 'buergschaften/anlegen/auftragnehmerAuswaehlen',
      name: 'bürgschaftAnlegenAuftragnehmerAuswählen',
      component: () =>
        import('./components/buergschaften/aktionen/anlegen/BeteiligtesUnternehmenAuswaehlen.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.IMPORTIEREN,
      },
    },
    {
      path: 'buergschaften/anlegen/auftragnehmerErfassen',
      name: 'bürgschaftAnlegenAuftragnehmerErfassen',
      component: () =>
        import(
          './components/buergschaften/aktionen/anlegen/BeteiligtesUnternehmenDatenErfassen.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.IMPORTIEREN,
      },
    },
    {
      path: 'buergschaften/anlegen/auftraggeberAuswaehlen',
      name: 'buergschaftAnlegenAuftraggeberAuswaehlen',
      component: () =>
        import('./components/buergschaften/aktionen/anlegen/EigenesUnternehmenAuswaehlen.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.IMPORTIEREN,
      },
    },
    {
      path: 'buergschaften/anlegen/buergschaftsdatenErfassen',
      name: 'buergschaftAnlegenBuergschaftsdatenErfassen',
      component: () =>
        import('./components/buergschaften/aktionen/anlegen/BuergschaftsdatenErfassen.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.IMPORTIEREN,
      },
    },
    {
      path: 'buergschaften/anlegen/buergschaftsZusammenfassung',
      name: 'bürgschaftAnlegenZusammenfassung',
      component: () => import('./components/buergschaften/aktionen/anlegen/Zusammenfassung.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.IMPORTIEREN,
      },
    },
    {
      path: 'buergschaften/anlegen/buergeAuswaehlen',
      name: 'buergschaftAnlegenBuergeAuswaehlen',
      component: () => import('./components/buergschaften/aktionen/anlegen/BuergeAuswaehlen.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.IMPORTIEREN,
      },
    },
    {
      path: 'buergschaften/:buergschaftId(\\d+)/enthaften',
      name: 'bürgschaftEnthaftenAuftraggeber',
      props: true,
      component: () => import('./components/buergschaften/aktionen/BuergschaftEnthaften.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.ENTHAFTEN,
      },
    },
    {
      path: 'buergschaften/:buergschaftId(\\d+)/teilenthaften',
      name: 'bürgschaftTeilenthaftenAuftraggeber',
      props: true,
      component: () => import('./components/buergschaften/aktionen/BuergschaftTeilenthaften.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.TEILENTHAFTEN,
      },
    },
    {
      path: 'buergschaften/:buergschaftId(\\d+)/inanspruchnehmen',
      name: 'bürgschaftInanspruchnehmenAuftraggeber',
      props: true,
      component: () =>
        import(
          './components/buergschaften/aktionen/inanspruchnehmen/BuergschaftInanspruchnehmen.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.IN_ANSPRUCH_NEHMEN,
      },
    },
    {
      path: 'buergschaften/hochladen/csv',
      name: 'buergschaftenCsvHochladenAg',
      component: () => import('./components/buergschaften/BuergschaftCsvHochladen.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.IMPORTIEREN,
      },
    },
    {
      path: 'buergschaften/hochladen/csv/ergebnis',
      name: 'buergschaftenCsvHochladenErgebnisAg',
      component: () => import('./components/buergschaften/BuergschaftCsvHochladenErgebnis.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.IMPORTIEREN,
      },
    },
    /** BÜRGSCHAFT TEILEN */
    {
      path: 'buergschaften/:buergschaftId(\\d+)/teilen',
      name: 'bürgschaftTeilen',
      props: true,
      component: () => import('./components/buergschaften/aktionen/teilen/BuergschaftTeilen.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.PRÜFEN,
      },
    },

    /** BÜRGSCHAFTSTEXTE */
    {
      path: 'buergschaftstexte/uebersicht',
      name: 'bürgschaftstexteKachelÜbersichtAuftraggeber',
      component: () =>
        import('./components/buergschaftstexte/BuergschaftstexteKachelUebersicht.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaftstexte/anvorauszahlungstexte/liste',
      name: 'anVorauszahlungstexteAuftraggeber',
      component: () =>
        import('./components/buergschaftstexte/buergschaftstextarten/AnVorauszahlungstexte.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaftstexte/ausfuehrungstexte/liste',
      name: 'ausführungstexteAuftraggeber',
      component: () =>
        import('./components/buergschaftstexte/buergschaftstextarten/AusfuehrungstexteListe.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaftstexte/maengelanspruechetexte/liste',
      name: 'mängelansprüchetexteAuftraggeber',
      component: () =>
        import(
          './components/buergschaftstexte/buergschaftstextarten/MaengelanspruechetexteListe.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaftstexte/vertragserfuellungstexte/liste',
      name: 'vertragserfüllungstexteAuftraggeber',
      component: () =>
        import(
          './components/buergschaftstexte/buergschaftstextarten/VertragserfuellungstexteListe.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaftstexte/auftraggebertext/details/:buergschaftstemplateId([A-Z][A-Z]\\d{4}-\\d{4}-\\d{3})',
      name: 'auftraggebertextDetailsAuftraggeber',
      props: true,
      component: AuftraggebertextDetails,
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaftstexte/auftraggebertext/details/:vorlageId',
      name: 'vorlageDetailsAuftraggeber',
      props: true,
      component: AuftraggebertextDetails,
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaftstexte/auftraggebertext/:buergschaftstemplateId([A-Z][A-Z]\\d{4}-\\d{4}-\\d{3})?/hochladen',
      name: 'auftraggebertextHochladenAuftraggeber',
      props: true,
      component: () => import('./components/buergschaftstexte/AuftraggebertextHochladen.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.AUSWÄHLEN,
      },
    },
    {
      path: 'buergschaftstexte/auftraggebertext/hochladen/erfolg',
      name: 'auftraggebertextHochladenErfolgAuftraggeber',
      component: () => import('./components/buergschaftstexte/AuftraggebertextHochladenErfolg.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.AUSWÄHLEN,
      },
    },
    {
      path: 'buergschaftstexte/auftraggebertext/hochladen/version/:buergschaftstemplateId([A-Z][A-Z]\\d{4}-\\d{4}-\\d{3})',
      name: 'bürgschaftstextHochladenVersionÜbersichtAuftraggeber',
      props: true,
      component: () =>
        import('./components/buergschaftstexte/AuftraggebertextHochladenVersionUebersicht.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.AUSWÄHLEN,
      },
    },
    {
      path: 'buergschaftstexte/buergentext/details/:buergschaftstemplateId([A-Z][A-Z]\\d{4}-\\d{4}-\\d{3})',
      name: 'bürgentextDetailsAuftraggeber',
      props: true,
      component: () => import('./components/buergschaftstexte/BuergentextDetails.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaftstexte/oeffentlichertext/details/:buergschaftstemplateId([A-Z][A-Z]\\d{4}-\\d{4}-\\d{3})',
      name: 'öffentlicherTextDetailsAuftraggeber',
      props: true,
      component: () => import('./components/buergschaftstexte/OeffentlicherTextDetails.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaftstexte/anlegen/:buergschaftstemplateId([A-Z][A-Z]\\d{4}-\\d{4}-\\d{3})?/textauswahl',
      name: 'bürgschaftstextAnlegenTextAuswahlAuftraggeber',
      props: true,
      component: () =>
        import('./components/buergschaftstexte/BuergschaftstextAnlegenTextAuswahl.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.AUSWÄHLEN,
      },
    },
    {
      path: 'buergschaftstexte/anlegen/textartauswahl',
      name: 'bürgschaftstextAnlegenTextartAuswahlAuftraggeber',
      component: () =>
        import('./components/buergschaftstexte/BuergschaftstextAnlegenTextartAuswahl.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.AUSWÄHLEN,
      },
    },
    {
      path: 'buergschaftstexte/buergentext/:buergschaftstemplateId([A-Z][A-Z]\\d{4}-\\d{4}-\\d{3})?/aktivieren',
      name: 'bürgenTextHinzufügenAuftraggeber',
      props: true,
      component: () => import('./components/buergschaftstexte/BuergentextAktivieren.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.AUSWÄHLEN,
      },
    },
    {
      path: 'buergschaftstexte/oeffentlichertext/:buergschaftstemplateId([A-Z][A-Z]\\d{4}-\\d{4}-\\d{3})?/aktivieren',
      name: 'öffentlicherTextHinzufügenAuftraggeber',
      props: true,
      component: () => import('./components/buergschaftstexte/ÖffentlicherTextAktivieren.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.AUSWÄHLEN,
      },
    },
    {
      path: 'buergschaftstexte/anlegen/:buergschaftstemplateId([A-Z][A-Z]\\d{4}-\\d{4}-\\d{3})?/zusammenfassung',
      name: 'bürgschaftstextAnlegenZusammenfassungAuftraggeber',
      props: true,
      component: () =>
        import('./components/buergschaftstexte/BuergschaftstextAnlegenZusammenfassung.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.AUSWÄHLEN,
      },
    },
    {
      path: 'buergschaftstexte/anlegen/erfolg',
      name: 'bürgschaftstextAnlegenErfolgAuftraggeber',
      component: () => import('./components/buergschaftstexte/BuergschaftstextAnlegenErfolg.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.AUSWÄHLEN,
      },
    },
    {
      path: 'buergschaftstexte/auftraggebertext/:buergschaftstemplateId([A-Z][A-Z]\\d{4}-\\d{4}-\\d{3})?//archivieren',
      name: 'buergschaftstextArchivierenAuftraggeber',
      component: () =>
        import('./components/buergschaftstexte/common/BuergschaftstextArchivieren.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },

    /** FEHLERHAFTE ALTBÜRGSCHAFTEN HERUNTERLADEN */
    {
      path: 'buergschaften/fehlerhafte-herunterladen/:dateinameUrlFormat',
      name: 'fehlerhafteAltbuergschaftenHerunterladen',
      props: true,
      component: () =>
        import(
          './components/buergschaften/import/fehlerhafte-herunterladen/FehlerhafteAltbuergschaftenHerunterladen.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.IMPORTIEREN,
      },
    },
    {
      path: 'buergschaften/fehlerhafte-herunterladen/:dateinameUrlFormat/ergebnis',
      name: 'fehlerhafteAltbuergschaftenHerunterladenErgebnis',
      component: () =>
        import(
          './components/buergschaften/import/fehlerhafte-herunterladen/FehlerhafteAltbuergschaftenHerunterladenErgebnis.vue'
        ),
      props: true,
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.IMPORTIEREN,
      },
    },

    /** UNTERNEHMEN */
    {
      path: 'unternehmen/uebersicht',
      name: 'unternehmenKachelÜbersichtAuftraggeber',
      component: () => import('./components/unternehmen/UnternehmenUebersicht.vue'),
      meta: {
        kategorie: [KATEGORIE.UNTERNEHMEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'unternehmen/anlegen',
      name: 'unternehmenErstellenAuftraggeber',
      component: () => import('./components/unternehmen/UnternehmenErstellen.vue'),
      meta: {
        kategorie: [KATEGORIE.UNTERNEHMEN],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'unternehmen/:tochterUnternehmenId([A-Z][A-Z]\\d{4})/bearbeiten',
      name: 'unternehmenBearbeitenAuftraggeber',
      props: true,
      component: () => import('./components/unternehmen/UnternehmenBearbeiten.vue'),
      meta: {
        kategorie: [KATEGORIE.UNTERNEHMEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },

    /** PROJEKTE */
    {
      path: 'projekte/liste',
      name: 'projekteListeAuftraggeber',
      component: () => import('./components/projekte/ProjekteListe.vue'),
      meta: {
        kategorie: [KATEGORIE.PROJEKTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'projekte/:id/uebersicht',
      name: 'projektÜbersichtAuftraggeber',
      props: true,
      component: () => import('./components/projekte/ProjektUebersicht.vue'),
      meta: {
        kategorie: [KATEGORIE.PROJEKTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'projekte/:id/loeschen/erfolg',
      name: 'projektLöschenErfolgAuftraggeber',
      component: () => import('./components/projekte/aktionen/loeschen/ProjektLoeschenErfolg.vue'),
      meta: {
        kategorie: [KATEGORIE.PROJEKTE],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },

    /** PROJEKTE: Bürgschaft einem Projekt zuordnen (aus der Bürgschaft-Detail-View heraus) * */
    {
      path: 'projekte/:id/buergschaft-zuordnen',
      name: 'projektBürgschaftenZuordnenAuftraggeber',
      props: true,
      component: () =>
        import('./components/projekte/aktionen/zuordnen/ProjektBuergschaftenZuordnen.vue'),
      meta: {
        kategorie: [KATEGORIE.PROJEKTE],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'buergschaften/:buergschaftId(\\d+)/korrektur/bestaetigung',
      name: 'bürgschaftKorrekturBestätigen',
      props: true,
      component: () =>
        import('./components/buergschaften/aktionen/BuergschaftKorrekturBestaetigen.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.PRÜFEN,
      },
    },
    {
      path: 'projekte/buergschaft-zuordnen/optionen',
      name: 'bürgschaftProjektZuordnenOptionenAuftraggeber',
      props: {
        isZuordnen: true,
        contentKey: 'zuordnen',
      },
      component: BuergschaftDetails,
      meta: {
        kategorie: [KATEGORIE.PROJEKTE],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'projekte/buergschaft-zuordnen/optionen',
      name: 'bürgschaftAkzeptierenProjektZuordnenOptionenAuftraggeber',
      props: {
        isZuordnen: true,
        contentKey: 'zuordnen',
        fromAkzeptieren: true,
      },
      component: BuergschaftDetails,
      meta: {
        kategorie: [KATEGORIE.PROJEKTE],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'projekte/buergschaft-zuordnen/projekt-auswaehlen',
      name: 'projektAuswählenBürgschaftZuordnenAuftraggeber',
      props: true,
      component: () =>
        import('./components/projekte/aktionen/zuordnen/BuergschaftZuordnenProjekteListe.vue'),
      meta: {
        kategorie: [KATEGORIE.PROJEKTE],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'projekte/buergschaft-zuordnen/erfolg',
      name: 'bürgschaftProjektZuordnenErfolgAuftraggeber',
      props: true,
      component: () =>
        import('./components/projekte/aktionen/zuordnen/BuergschaftProjektZuordnenErfolg.vue'),
      meta: {
        kategorie: [KATEGORIE.PROJEKTE],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    /** PROJEKTE NEU */
    createProjektRouteEntry(
      'projekte/:id/bearbeiten',
      AuftraggeberRoutes.AG_PROJEKT_FORM_BEARBEITEN,
      'bearbeiten',
    ),
    createProjektRouteEntry(
      'projekte/anlegen',
      AuftraggeberRoutes.AG_PROJEKT_FORM_ANLEGEN,
      'erstellen',
    ),

    /** VERTRÄGE */
    {
      path: 'vertraege/liste',
      name: AuftraggeberRoutes.AG_VERTRAEGE_LISTE,
      component: () => import('./components/vertraege/VertraegeListe.vue'),
      meta: {
        kategorie: [KATEGORIE.VERTRÄGE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'vertraege/details/:kautionsvertragId(\\d+)',
      name: 'vertragDetailsAuftraggeber',
      props: true,
      component: () => import('./components/vertraege/VertragDetails.vue'),
      meta: {
        kategorie: [KATEGORIE.VERTRÄGE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'vertraege/anlegen',
      name: AuftraggeberRoutes.AG_VERTRAG_ANLEGEN,
      props: true,
      component: () =>
        import('./components/vertraege/views/VertragAnlegen/VertragAnlegenBaseView.vue'),
      meta: {
        kategorie: [KATEGORIE.VERTRÄGE],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
      redirect: { name: AuftraggeberRoutes.AG_VERTRAG_ANLEGEN_BUERGE },
      children: [
        {
          path: 'buerge',
          name: AuftraggeberRoutes.AG_VERTRAG_ANLEGEN_BUERGE,
          component: () =>
            import('./components/vertraege/views/VertragAnlegen/VertragAnlegenBuerge.vue'),
        },
        {
          path: 'versicherungsnehmer',
          name: AuftraggeberRoutes.AG_VERTRAG_ANLEGEN_VERSICHERUNGSNEHMER,
          component: () =>
            import(
              './components/vertraege/views/VertragAnlegen/VertragAnlegenVersicherungsnehmer.vue'
            ),
        },
        {
          path: 'vertragsdaten',
          name: AuftraggeberRoutes.AG_VERTRAG_ANLEGEN_VERTRAGSDATEN,
          component: () =>
            import('./components/vertraege/views/VertragAnlegen/VertragAnlegenVertragsdaten.vue'),
        },
        {
          path: 'unternehmen',
          name: AuftraggeberRoutes.AG_VERTRAG_ANLEGEN_UNTERNEHMEN,
          component: () =>
            import('./components/vertraege/views/VertragAnlegen/VertragAnlegenUnternehmen.vue'),
        },
        {
          path: 'zusammenfassung',
          name: AuftraggeberRoutes.AG_VERTRAG_ANLEGEN_ZUSAMMENFASSUNG,
          component: () =>
            import('./components/vertraege/views/VertragAnlegen/VertragAnlegenZusammenfassung.vue'),
        },
      ],
    },
    {
      path: 'vertraege/:kautionsvertragId(\\d+)/bearbeiten',
      name: 'vertragBearbeitenAuftraggeber',
      props: true,
      component: () => import('./components/vertraege/aktionen/bearbeiten/VertragBearbeiten.vue'),
      meta: {
        kategorie: [KATEGORIE.VERTRÄGE],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'vertraege/:kautionsvertragId(\\d+)/bearbeiten/unternehmen-auswahl',
      name: 'vertragBearbeitenUnternehmenAuswählenAuftraggeber',
      props: true,
      component: () =>
        import(
          './components/vertraege/aktionen/bearbeiten/VertragBearbeitenUnternehmenAuswaehlen.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.VERTRÄGE],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'vertraege/:kautionsvertragId(\\d+)/bearbeiten/unternehmen-hinzufuegen',
      name: 'vertragUnternehmenHinzufuegenAuftraggeber',
      props: true,
      component: () => import('./components/vertraege/VertragUnternehmenHinzufuegen.vue'),
      meta: {
        kategorie: [KATEGORIE.VERTRÄGE],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'vertraege/:kautionsvertragId(\\d+)/bearbeiten/zusammenfassung',
      name: 'vertragBearbeitenZusammenfassungAuftraggeber',
      props: true,
      component: () =>
        import('./components/vertraege/aktionen/bearbeiten/VertragBearbeitenZusammenfassung.vue'),
      meta: {
        kategorie: [KATEGORIE.VERTRÄGE],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },

    /** NUTZER UND ROLLEN */
    {
      path: 'nutzer/liste/:aktiverTab?',
      name: 'nutzerRollenListeAuftraggeber',
      component: () => import('./components/nutzer/Views/NutzerRollenListe.vue'),
      meta: {
        kategorie: [KATEGORIE.NUTZER, KATEGORIE.ROLLEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'nutzer/anlegen',
      name: 'nutzerErstellenAuftraggeber',
      component: () => import('./components/nutzer/Views/NutzerErstellen.vue'),
      meta: {
        kategorie: [KATEGORIE.NUTZER],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'nutzer/wiederherstellen',
      name: 'nutzerWiederherstellen',
      component: () => import('./components/nutzer/Views/NutzerWiederherstellen.vue'),
      meta: {
        kategorie: [KATEGORIE.NUTZER],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'nutzer/anlegen/unternehmen-auswaehlen',
      name: 'nutzerWiederherstellenUnternehmenAuswahlAuftraggeber',
      props: {
        isWiederherstellen: true,
        isBearbeiten: false,
      },
      component: NutzerUnternehmenAuswaehlen,
      meta: {
        kategorie: [KATEGORIE.NUTZER],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'nutzer/anlegen/unternehmen-auswaehlen',
      name: 'nutzerHinzufügenUnternehmenAuswahlAuftraggeber',
      props: {
        isBearbeiten: false,
      },
      component: NutzerUnternehmenAuswaehlen,
      meta: {
        kategorie: [KATEGORIE.NUTZER],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'nutzer/:nutzerId([0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12})/bearbeiten',
      name: 'nutzerBearbeitenAuftraggeber',
      props: true,
      component: () => import('./components/nutzer/Views/NutzerBearbeiten.vue'),
      meta: {
        kategorie: [KATEGORIE.NUTZER],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'nutzer/:nutzerId([0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12})/bearbeiten/unternehmen-auswaehlen',
      name: 'nutzerBearbeitenKeineÄnderungUnternehmenAuswahlAuftraggeber',
      props: {
        isBearbeiten: true,
        isNutzerdatenBearbeitet: false,
      },
      component: NutzerUnternehmenAuswaehlen,
      meta: {
        kategorie: [KATEGORIE.NUTZER],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'nutzer/:nutzerId([0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12})/bearbeiten/unternehmen-auswaehlen',
      name: 'nutzerBearbeitenÄnderungUnternehmenAuswahlAuftraggeber',
      props: {
        isBearbeiten: true,
        isNutzerdatenBearbeitet: true,
      },
      component: NutzerUnternehmenAuswaehlen,
      meta: {
        kategorie: [KATEGORIE.NUTZER],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'nutzer/rollen/anlegen',
      name: 'rolleErstellenAuftraggeber',
      component: () => import('./components/nutzer/Views/RolleErstellen.vue'),
      meta: {
        kategorie: [KATEGORIE.ROLLEN],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'nutzer/rollen/bearbeiten',
      name: 'rolleBearbeitenAuftraggeber',
      component: () => import('./components/nutzer/Views/RolleBearbeiten.vue'),
      meta: {
        kategorie: [KATEGORIE.ROLLEN],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },

    /** KONTOÜBERSICHT */
    {
      path: 'kontouebersicht',
      name: 'kontoÜbersichtAuftraggeber',
      component: () => import('../shared/Nutzer/Components/KontoUebersicht.vue'),
    },

    /** ERROR SCREEN FEHLENDE RECHTE */
    {
      path: 'error',
      name: 'fehlerScreenFehlendeRechteAuftraggeber',
      component: () => import('./components/common/FehlerScreenFehlendeRechte.vue'),
    },
  ],
};

export { auftraggeberRoutes };
