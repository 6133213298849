<template>
  <TRibbon
    v-if="zeigePlattformHinweis"
    :badge="plattformHinweis.badge"
    :variant="plattformHinweis.typ"
    :button-target="plattformHinweis.link"
    :button-text="plattformHinweis.buttonText"
    closable
    @close="markierePlattformHinweisGesehen"
  >
    {{ plattformHinweis.beschreibung }}
  </TRibbon>
  <div class="app-container">
    <RouterView />
  </div>
</template>

<script setup lang="ts">
import { RouterView } from 'vue-router';
import { onBeforeMount } from 'vue';
import { useBuergenStore } from '@/shared/Buergen/useBuergenStore';
import type { Buerge } from '@/shared/Buergen/types';
import TRibbon from '@/design-system/atoms/TRibbon/TRibbon.vue';
import { usePlattformHinweise } from '@/shared/PlattformHinweise/usePlattformHinweise';
import assetApi from '@/connection/buergenassets-api';
import { getNutzer } from './service/TokenService';
import partnerserviceApi from './connection/partnerservice-api';
import {
  createEmptyLänderStorage as createEmptyLaenderStorage,
  speichereLänderStorage as speichereLaenderStorage,
} from './persistence/LänderStorage';
import {
  createEmptyNavigationStorage,
  speichereNavigationStorage,
} from './persistence/NavigationStorage';
import { createEmptyRechteStorage, speichereRechteStorage } from './persistence/RechteStorage';

defineOptions({
  name: 'App',
});

const {
  plattformHinweis,
  zeigePlattformHinweis,
  markierePlattformHinweisGesehen,
  ladePlattformHinweis,
} = usePlattformHinweise();

const ladeLaender = async () => {
  const partnerResponse = await partnerserviceApi.findeLänderliste();
  const laenderStorage = createEmptyLaenderStorage();
  laenderStorage.länder = partnerResponse.data;
  speichereLaenderStorage(laenderStorage);
};

const ladeRollen = async () => {
  const partnerResponseEigene = await partnerserviceApi.holeEigeneRechte();
  const rechteStorage = createEmptyRechteStorage();
  rechteStorage.eigeneRechte = partnerResponseEigene.data;
  speichereRechteStorage(rechteStorage);
};

const ladeBuergenMap = async () => {
  const partnerResponse = await partnerserviceApi.findeAlleBürgen();
  const buergen: Buerge[] = partnerResponse.data;
  const buergenStore = useBuergenStore();

  buergen.forEach((b) => buergenStore.fuegeBuergeHinzu(b));
};

const setNutzer = async () => {
  const nutzer = getNutzer();
  const navigationStorage = createEmptyNavigationStorage();
  navigationStorage.nutzer = nutzer;
  navigationStorage.vollerName = `${nutzer.vorname} ${nutzer.nachname}`;
  speichereNavigationStorage(navigationStorage);
};

/**
 * Daten zum Nutzer abwarten, da
 * 1. wichtig für Namensanzeige und
 * 2. keine Probleme erwartet werden, da Keycloak ja erreichbar sein muss
 * (wir haben uns ja gerade eingeloggt).
 */
setNutzer().then(() => {
  // Nicht essenzielle Daten werden nachgelagert geladen und nicht abgewartet
  ladeRollen();
  ladePlattformHinweis();
  /**
   * Die Länder ohne direkten Bedarf vorweg zu laden ist unnötig. Sie sollten bei der ersten
   * Anfrage geholt und dann gecacht werden (idealerweise auch einfach über den Browser-Cache,
   * wie Cache-Header, den das Backend setzt)
   */
  ladeLaender();
  ladeBuergenMap();
});

onBeforeMount(() => {
  const stylesUrl = `${assetApi.getAssetContext()}/assets/buergen/styles.css`;
  const element = document.createElement('link');
  element.setAttribute('rel', 'stylesheet');
  element.setAttribute('type', 'text/css');
  element.setAttribute('href', stylesUrl);
  document.head.appendChild(element);
});
</script>

<style>
.app-container {
  display: flex;
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;
}

/*
  Setze global Styling für den Main-Content,
  wird in den BaseViews per Domäne auf dem RouteView gesetzt
*/
.app-container > .content-wrapper {
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  overflow-y: auto;
  padding: 100px 100px 100px 130px;
}

/** Fade Transitions */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
