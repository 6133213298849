import { setLocale } from 'yup';

import './yupExtensions/string';
import './yupExtensions/date';
import { zuDeutschemDatumString } from '@/shared/utilities/dates/dates';

setLocale({
  mixed: {
    required: 'Bitte füllen Sie dieses Feld aus',
  },

  string: {
    email: 'Bitte geben Sie eine gültige E-Mail-Adresse an',
    min: (params) => `Bitte geben Sie mindestens ${params.min} Zeichen ein`,
    max: (params) => `Bitte geben Sie maximal ${params.max} Zeichen ein`,
    length: (params) => `Bitte geben Sie exakt ${params.length} Zeichen ein`,
  },

  date: {
    min: (params) =>
      `Das Datum darf nicht vor dem ${zuDeutschemDatumString(params.min as Date)} liegen`,
  },
});
