import type { KeycloakOnLoad, KeycloakTokenParsed } from 'keycloak-js';
import Keycloak from 'keycloak-js';

import type { AxiosResponse } from 'axios';
import axios from 'axios';
import conf from '@/shared/config/useConfig';

// eslint-disable-next-line import/no-cycle
import partnerserviceApi from './partnerservice-api';

import { ladeRechteStorage, speichereRechteStorage } from '../persistence/RechteStorage';

import type { Nutzer } from '../types/Nutzer';
import { createEmptyNutzer } from '../types/Nutzer';
import { buildSelfUrl, buildUrlForServiceName } from './url-retriever';

const initOptions = {
  url: `${buildUrlForServiceName(conf.nutzerverwaltungName)}/nutzerverwaltung`,
  realm: 'bsp',
  clientId: 'login-service',
  onLoad: 'login-required' as KeycloakOnLoad, // keine Ahnung, warum man den Typ explizit angeben muss. Typescript sollte 'login-required' als valide Ausprägung von KeycloakOnLoad erkennen 🤔
  sessionStatusIframe: false,
};

const keycloak = Keycloak(initOptions);

/**
 * Nutzerprofil in Keycloak - wird von uns nicht verwendet - wir mappen auf Nutzer
 */
interface KeycloakProfileBsp {
  attributes: {
    locale: string;
    laendervorwahl: string;
    vorwahl: string;
    telefonnummer: string;
    unternehmenSichtbeschränkt: string[];
    erhaltNutzerEMails: string[];
    sichtberechtigteUnternehmen: string[];
  };
  email: string;
  emailVerified: boolean;
  firstName: string;
  lastName: string;
  username: string;
  id: string;
}

interface KeycloakInfoBsp {
  group: string[];
  sso: string;
}

/**
 * Mappt die Keycloak-Datenstruktur auf unseren Nutzer-Datentyp, den wir in der Anwendung verwenden
 */
function mapNutzer(profil: KeycloakProfileBsp, info: KeycloakInfoBsp): Nutzer {
  const nutzer = createEmptyNutzer();

  if (info.group) {
    const group = info.group[0].match(/^\/([^/]*)\//);
    [, nutzer.organisation] = group;
  }
  nutzer.vorname = profil.firstName;
  nutzer.nachname = profil.lastName;
  nutzer.nutzerId = profil.id;

  if (profil.attributes && profil.attributes.telefonnummer) {
    [nutzer.telefonnummer] = profil.attributes.telefonnummer;
  } else {
    nutzer.telefonnummer = '';
  }

  nutzer.email = profil.email;
  nutzer.sso = info.sso === 'true';

  if (profil.attributes?.erhaltNutzerEMails)
    nutzer.erhaltNutzerEMails = profil.attributes.erhaltNutzerEMails[0] === 'true';

  if (profil.attributes?.unternehmenSichtbeschränkt) {
    nutzer.unternehmenSichtbeschränkt = profil.attributes.unternehmenSichtbeschränkt[0] === 'true';
  }

  if (profil.attributes?.sichtberechtigteUnternehmen) {
    nutzer.sichtberechtigteUnternehmen = profil.attributes.sichtberechtigteUnternehmen;
  }

  return nutzer;
}

export interface CustomToken extends KeycloakTokenParsed {
  group: string[];
  organisation: string;
  sso: boolean;
  given_name: string;
  family_name: string;
  email: string;
  unternehmenSichtbeschränkt: boolean;
  erhaltNutzerEMails: boolean;
}

export const keycloakApi = {
  init(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      keycloak
        .init({ onLoad: initOptions.onLoad, checkLoginIframe: initOptions.sessionStatusIframe })
        .then((auth) => resolve(auth))
        .catch((e) => reject(e));
    });
  },

  getToken(): string {
    return keycloak.token;
  },

  getTokenParsed(): CustomToken {
    // Wir haben das Token erweitert (Keycloak-Konfiguration), daher ist das Casting auf CustomToken zulässig
    return keycloak.tokenParsed as CustomToken;
  },

  getEigeneUserId(): string {
    return this.getTokenParsed().sub;
  },

  /**
   * Lädt den Nutzer aus Keycloak. Zweitpräferierte Möglichkeit, um Informationen zum Nutzer zu holen.
   *
   * ACHTUNG: Diese Funktion bitte sparsam verwenden, da sie zwei Calls macht! In der Regel braucht man das hier garnicht,
   *          sondern TokenService.getNutzer(), der einfach nur die Informationen aus dem Token zieht. Dieses ist
   *          für die meisten Fälle völlig ausreichend!
   */
  async ladeNutzer(): Promise<Nutzer> {
    let profil;
    let info;

    const ladeProfil = async () => {
      profil = (await keycloak.loadUserProfile()) as KeycloakProfileBsp;
    };
    const ladeInfo = async () => {
      info = (await keycloak.loadUserInfo()) as KeycloakInfoBsp;
    };
    await Promise.all([ladeProfil(), ladeInfo()]);

    return mapNutzer(profil, info);
  },

  createAccountUrl(): string {
    return keycloak.createAccountUrl();
  },

  async checkPassword(password: string): Promise<AxiosResponse> {
    const params = new URLSearchParams();
    const userInfo = await keycloak.loadUserProfile();
    const { clientId } = keycloak;
    const keycloakUrl = keycloak.authServerUrl;

    params.append('grant_type', 'password');
    params.append('client_id', clientId);
    params.append('username', userInfo.username);
    params.append('password', password);
    params.append('scope', 'openid');

    return axios.post(`${keycloakUrl}/realms/bsp/protocol/openid-connect/token`, params);
  },

  /**
   * Hilfsfunktionen für den Keycloak-Adapter
   */
  clearStorageAndLogout() {
    sessionStorage.clear();
    // Bei erneutem Login wird der Nutzer dadurch auf die Startseite navigiert
    keycloak.logout({ redirectUri: `${buildSelfUrl()}/#/` });
  },

  async refreshToken(minValidity: number): Promise<void> {
    const refreshed = await keycloak.updateToken(minValidity);
    if (refreshed) {
      partnerserviceApi.holeEigeneRechte().then((response) => {
        const rechteStorage = ladeRechteStorage();
        rechteStorage.eigeneRechte = response.data;
        speichereRechteStorage(rechteStorage);
      });
      this.output('Token aktualisiert.');
    } else {
      this.output(
        `Token nicht aktualisiert, gültig für ${Math.round(
          keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000,
        )} Sekunden.`,
      );
    }
  },

  output(data: unknown) {
    if (typeof data === 'object') {
      // eslint-disable-next-line no-param-reassign
      data = JSON.stringify(data, null, '  ');
    }
  },

  /**
   * Events für Keycloak-Adapter
   */
  onAuthSuccess() {
    keycloak.onAuthSuccess = () => {
      this.output('Authentifizierung erfolgreich.');
    };
  },

  onAuthError() {
    keycloak.onAuthError = (errorData) => {
      this.output(`Authentifizierungsfehler: ${JSON.stringify(errorData)}`);
    };
  },

  onAuthRefreshSuccess() {
    keycloak.onAuthRefreshSuccess = () => {
      this.output('Aktualisierung des Refresh Tokens war erfolgreich.');
    };
  },

  onAuthRefreshError() {
    keycloak.onAuthRefreshError = () => {
      this.output('Aktualisierung des Refresh Tokens ist fehlgeschlagen.');
    };
  },

  onAuthLogout() {
    keycloak.onAuthLogout = () => {
      this.output('Logout (SSO) erfolgreich.');
    };
  },

  onActionUpdate() {
    keycloak.onActionUpdate = (status) => {
      switch (status) {
        case 'success':
          this.output('Aktion erfolgreich durchgeführt.');
          break;
        case 'cancelled':
          this.output('Aktion vom Benutzer abgebrochen.');
          break;
        case 'error':
          this.output('Aktion fehlgeschlagen.');
          break;
        default:
          break;
      }
    };
  },
};
