import { keycloakApi } from '@/connection/keycloak-api';
import { countdownService } from '@/service/CountdownService';
import type { ExtendedInternalAxiosRequestConfig } from '@/shared/api/clients/types';
import type { AxiosInstance, InternalAxiosRequestConfig } from 'axios';

let isRefreshing = false;

/**
 * Setzt das aktuell gültige JWT am Header (Authorization: Bearer $TOKEN).
 * wir setzen es nicht einmalig via client.defaults.headers.Authorization, weil wir ja immer das aktuellste Token haben wollen
 */
export const setToken = (config: InternalAxiosRequestConfig) => {
  if (config.headers && keycloakApi.getToken()) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${keycloakApi.getToken()}`;
  }
};

/**
 * Wiederholt einen fehlgeschlagenen Request. Holt sich aber vorher ein neues Token über die Keycloak-API
 */
export const handleInvalidToken = (
  originalRequest: ExtendedInternalAxiosRequestConfig,
  axiosInstance: AxiosInstance,
): unknown => {
  if (!isRefreshing) {
    // eslint-disable-next-line no-param-reassign, no-underscore-dangle
    originalRequest._retry = true;
    isRefreshing = true;

    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        await keycloakApi.refreshToken(60); // die minValidity ist ja hier egal, da das Token ja bereits invalide ist
        // eslint-disable-next-line no-param-reassign
        originalRequest.headers.Authorization = `Bearer ${keycloakApi.getToken()}`;
        resolve(axiosInstance(originalRequest));
      } catch (err) {
        reject(err);
      } finally {
        isRefreshing = false;
      }
    });
  }
  return Promise.resolve(axiosInstance(originalRequest));
};

export const resetSessionTimer = () => {
  countdownService.reset();
};
