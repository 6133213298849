import { addMethod, string } from 'yup';
import { blackList } from '@/shared/Validierung/ValidierungsRegeln/zeichenRegexListen';

// Übernommen von vee-validate, da unsere Anforderungen erfüllt werden.
const emailRegex =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const getInvalideCharacters = (value: string, regexp: RegExp): string[] => {
  if (!value) {
    return [];
  }

  const characters = Array.from(new Set(value.split('')));

  const invalideZeichen: string[] = [];

  characters.forEach((c) => {
    if (!c.match(regexp)) {
      invalideZeichen.push(c);
    }
  });

  return invalideZeichen;
};

export const getBlackListMatches = (value: string) => {
  if (!value) {
    return [];
  }

  const matches = value.match(blackList);
  if (!matches || matches.length === 0) {
    return [];
  }

  return Array.from(new Set(matches));
};

addMethod(string, 'matchExpression', function matchExpression(regexp: RegExp, message?: string) {
  return this.test({
    test(value) {
      // Für alle falsy Values geben wir true zurück, da hier nichts negativ matchen kann
      if (!value) {
        return true;
      }

      const invalideZeichen = getInvalideCharacters(value, regexp);

      if (invalideZeichen.length !== 0) {
        if (message) {
          return this.createError({ message });
        }

        if (invalideZeichen.length === 1) {
          return this.createError({
            message: `Das Zeichen ${invalideZeichen[0]} kann nicht verwendet werden.`,
          });
        }
        return this.createError({
          message: `Die Zeichen ${invalideZeichen.join(', ')} können nicht verwendet werden.`,
        });
      }

      return true;
    },
  });
});

addMethod(string, 'matchBlacklist', function matchBlacklist() {
  return this.test({
    test(value) {
      // Für alle falsy Values geben wir true zurück, da hier nichts negativ matchen kann
      if (!value) {
        return true;
      }

      const invalideZeichen = getBlackListMatches(value);

      if (invalideZeichen.length !== 0) {
        if (invalideZeichen.length === 1) {
          return this.createError({
            message: `Das Zeichen ${invalideZeichen[0]} kann nicht verwendet werden.`,
          });
        }
        return this.createError({
          message: `Die Zeichenfolgen ${invalideZeichen.join(', ')} können nicht verwendet werden.`,
        });
      }

      return true;
    },
  });
});

/**
 * Wir überschreiben die original yup Methode 'email' da diese nach dem HTML Spec validiert, das
 * ist aber nicht ausreichend für unsere Zwecke.
 */
addMethod(string, 'email', function validateEmail(message) {
  return this.matches(emailRegex, {
    message: message ?? 'Bitte geben Sie eine gültige E-Mail-Adresse an',
    name: 'email',
    excludeEmptyString: true,
  });
});

/**
 * Erweiterung von yup um die validPercentage Validierung
 */

addMethod(string, 'validPercentage', function validatePercentage() {
  return this.test({
    name: 'validPercentage',
    message: 'Bitte geben Sie eine Zahl zwischen 0,01% und 100% ein.',
    test: (value) => {
      if (!value) return true;
      const cleanedProzentString = value.replace(/\./g, '').replace(',', '.');
      const prozentBetrag = parseFloat(cleanedProzentString);
      return !Number.isNaN(prozentBetrag) && prozentBetrag >= 0.01 && prozentBetrag <= 100;
    },
  });
});
