import { defineStore } from 'pinia';
import type { HistoryRoute } from '@/shared/navigation/useRouteStore/types';

interface State {
  aktuelleRoute: HistoryRoute;
  vorherigeRoute: HistoryRoute;
  routeHistory: HistoryRoute[];
  routeParams: Record<string, unknown>;
}

export const useRouteStore = defineStore('RouteStore', {
  state: (): State => ({
    aktuelleRoute: { name: '' },
    vorherigeRoute: { name: '' },
    routeHistory: [],
    routeParams: {},
  }),

  actions: {
    setzeAktuelleRoute(route: HistoryRoute) {
      this.vorherigeRoute = this.aktuelleRoute;
      this.aktuelleRoute = route;
      this.aktuelleRoute.routeParams = this.routeParams;
      this.routeHistory.push(route);
    },

    getRouteParams<T extends Record<string, unknown>>(): T {
      const params = this.routeParams;
      this.routeParams = {};
      return params as T;
    },
  },
});
