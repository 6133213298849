import type { RouteRecordRaw } from 'vue-router';
import { ANZEIGENAME, KATEGORIE } from '@/persistence/RechteStorage';
import { BuergeRoutes } from '@/buerge/routeHelpers';

const buergeRoutes: RouteRecordRaw = {
  path: '/buerge',
  component: () => import('./BuergeView.vue'),
  name: 'buerge',
  children: [
    /** AUFGABEN */
    {
      path: 'aufgaben/liste',
      name: BuergeRoutes.BUERGE_AUFGABEN_LISTE,
      component: () => import('./components/aufgaben/AufgabenListe.vue'),
      meta: {
        kategorie: [KATEGORIE.AUFGABEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },

    /** BÜRGSCHAFTSANTRÄGE */

    /** Liste aller Bürgschaftsanträge */
    {
      path: 'buergschaftsauftraege/liste',
      name: 'buergschaftsauftraegeListeBuerge',
      component: () => import('./components/buergschaftsauftraege/BuergschaftsauftragListe.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    /** Bürgschaftsantrag mit den Details anzeigen */
    {
      path: 'buergschaftsauftraege/details/:buergschaftsauftragId(\\d+)',
      name: BuergeRoutes.BUERGE_ANTRAG_DETAIL,
      props: true,
      component: () => import('./components/buergschaftsauftraege/BuergschaftsauftragDetails.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },

    {
      name: BuergeRoutes.BUERGE_ANTRAG_PRUEFEN_AUFGABE_BASE,
      path: 'buergschaftsauftraege/pruefen/:buergschaftsauftragId(\\d+)',
      props: true,
      component: () =>
        import('./components/buergschaftsauftraege/views/AntragPruefen/AntragPruefenBaseView.vue'),
      redirect: { name: BuergeRoutes.BUERGE_ANTRAG_PRUEFEN_AUFGABE_DETAILS },
      children: [
        {
          name: BuergeRoutes.BUERGE_ANTRAG_PRUEFEN_AUFGABE_DETAILS,
          path: 'details',
          props: true,
          component: () =>
            import(
              './components/buergschaftsauftraege/views/AntragPruefen/AntragPruefenDetails.vue'
            ),
        },

        {
          name: BuergeRoutes.BUERGE_ANTRAG_PRUEFEN_AUFGABE_ENTSCHEIDUNG,
          path: 'entscheidung',
          props: true,
          component: () =>
            import(
              './components/buergschaftsauftraege/views/AntragPruefen/AntragPruefenEntscheidung.vue'
            ),
          beforeEnter: (to, from, next) => {
            const validSteps: string[] = [
              BuergeRoutes.BUERGE_ANTRAG_PRUEFEN_AUFGABE_DETAILS,
              BuergeRoutes.BUERGE_ANTRAG_PRUEFEN_AUFGABE_ABLEHNEN,
              BuergeRoutes.BUERGE_ANTRAG_PRUEFEN_AUFGABE_AKZEPTIEREN,
            ];

            if (validSteps.includes(from.name.toString())) {
              return next();
            }
            return next({ name: BuergeRoutes.BUERGE_ANTRAG_PRUEFEN_AUFGABE_DETAILS });
          },
        },
        {
          name: BuergeRoutes.BUERGE_ANTRAG_PRUEFEN_AUFGABE_AKZEPTIEREN,
          path: 'akzeptieren',
          props: true,
          component: () =>
            import(
              './components/buergschaftsauftraege/views/AntragPruefen/AntragPruefenAkzeptieren.vue'
            ),
          beforeEnter: (to, from, next) => {
            const validSteps: string[] = [BuergeRoutes.BUERGE_ANTRAG_PRUEFEN_AUFGABE_ENTSCHEIDUNG];

            if (validSteps.includes(from.name.toString())) {
              return next();
            }
            return next({ name: BuergeRoutes.BUERGE_ANTRAG_PRUEFEN_AUFGABE_DETAILS });
          },
        },

        {
          name: BuergeRoutes.BUERGE_ANTRAG_PRUEFEN_AUFGABE_ABLEHNEN,
          path: 'ablehnen',
          props: true,
          component: () =>
            import(
              './components/buergschaftsauftraege/views/AntragPruefen/AntragPruefenAblehnen.vue'
            ),
          beforeEnter: (to, from, next) => {
            const validSteps: string[] = [BuergeRoutes.BUERGE_ANTRAG_PRUEFEN_AUFGABE_ENTSCHEIDUNG];

            if (validSteps.includes(from.name.toString())) {
              return next();
            }
            return next({ name: BuergeRoutes.BUERGE_ANTRAG_PRUEFEN_AUFGABE_DETAILS });
          },
        },
      ],
    },

    /** BÜRGSCHAFTEN */
    {
      path: 'buergschaften/liste',
      name: 'bürgschaftenListeBürge',
      component: () => import('./components/buergschaften/BuergschaftenListe.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },

    /** BÜRGSCHAFTEN-ANLEGEN */
    {
      path: 'buergschaften/anlegen',
      name: BuergeRoutes.BUERGE_BUERGSCHAFT_ANLEGEN,
      props: true,
      component: () =>
        import('@/buerge/components/buergschaften/views/anlegen/BuergschaftAnlegenBase.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.MANUELL_VERWALTEN,
      },
      redirect: { name: BuergeRoutes.BUERGE_BUERGSCHAFT_ANLEGEN_AUFTRAGGEBER },
      children: [
        {
          path: 'auftraggeber',
          name: BuergeRoutes.BUERGE_BUERGSCHAFT_ANLEGEN_AUFTRAGGEBER,
          component: () =>
            import(
              '@/buerge/components/buergschaften/views/anlegen/BuergschaftAnlegenAuftraggeber.vue'
            ),
        },
        {
          path: 'auftragnehmer',
          name: BuergeRoutes.BUERGE_BUERGSCHAFT_ANLEGEN_AUFTRAGNEHMER,
          component: () =>
            import(
              '@/buerge/components/buergschaften/views/anlegen/BuergschaftAnlegenAuftragnehmer.vue'
            ),
        },
        {
          path: 'buergschaftsdaten',
          name: BuergeRoutes.BUERGE_BUERGSCHAFT_ANLEGEN_BUERGSCHAFTSDATEN,
          component: () =>
            import(
              '@/buerge/components/buergschaften/views/anlegen/BuergschaftAnlegenBuergschaftsdaten.vue'
            ),
        },
        {
          path: 'buergschaftsdokument',
          name: BuergeRoutes.BUERGE_BUERGSCHAFT_ANLEGEN_BUERGSCHAFTSDOKUMENT,
          component: () =>
            import(
              '@/buerge/components/buergschaften/views/anlegen/BuergschaftAnlegenBuergschaftsdokument.vue'
            ),
        },
        {
          path: 'zusammenfassung',
          name: BuergeRoutes.BUERGE_BUERGSCHAFT_ANLEGEN_ZUSAMMENFASSUNG,
          component: () =>
            import(
              '@/buerge/components/buergschaften/views/anlegen/BuergschaftAnlegenZusammenfassung.vue'
            ),
        },
      ],
    },

    {
      path: 'buergschaften/:buergschaftId(\\d+)/korrigieren/uebersicht-korrekturanforderung',
      name: 'bürgschaftKorrigierenÜbersichtKorrekturanforderungBürge',
      props: true,
      component: () =>
        import(
          './components/buergschaften/aktionen/korrigieren/BuergschaftKorrigierenUebersichtKorrekturanforderung.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.MANUELL_VERWALTEN,
      },
    },

    {
      path: 'buergschaften/:buergschaftId(\\d+)/korrigieren/uebersicht-auftraggeber',
      name: 'bürgschaftKorrigierenÜbersichtAuftraggeberBürge',
      props: true,
      component: () =>
        import(
          './components/buergschaften/aktionen/korrigieren/BuergschaftKorrigierenUebersichtAuftraggeber.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.MANUELL_VERWALTEN,
      },
    },

    {
      path: 'buergschaften/:buergschaftId(\\d+)/korrigieren/auftraggeber-auswaehlen',
      name: 'bürgschaftKorrigierenAuftraggeberAuswählenBürge',
      props: true,
      component: () =>
        import(
          './components/buergschaften/aktionen/korrigieren/BuergschaftKorrigierenAuftraggeberAuswaehlen.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.MANUELL_VERWALTEN,
      },
    },

    {
      path: 'buergschaften/:buergschaftId(\\d+)/korrigieren/vertretungsformel-erfassen',
      name: 'buergschaftKorrigierenVertretungsformelErfassenBürge',
      component: () =>
        import(
          './components/buergschaften/aktionen/korrigieren/BuergschaftKorrigierenVertretungsformelErfassen.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.MANUELL_VERWALTEN,
      },
    },

    {
      path: 'buergschaften/:buergschaftId(\\d+)/korrigieren/auftragnehmer-daten-erfassen',
      name: 'bürgschaftKorrigierenAuftragnehmerDatenErfassenBürge',
      props: true,
      component: () =>
        import(
          './components/buergschaften/aktionen/korrigieren/BuergschaftKorrigierenAuftragnehmerDatenErfassen.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.MANUELL_VERWALTEN,
      },
    },

    {
      path: 'buergschaften/:buergschaftId(\\d+)/korrigieren/buergschaftsdaten-erfassen',
      name: 'bürgschaftKorrigierenBürgschaftsdatenErfassenBürge',
      props: true,
      component: () =>
        import(
          './components/buergschaften/aktionen/korrigieren/BuergschaftKorrigierenBuergschaftsdatenErfassen.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.MANUELL_VERWALTEN,
      },
    },

    {
      path: 'buergschaften/:buergschaftId(\\d+)/korrigieren/buergschaftsdokument-hochladen',
      name: 'bürgschaftKorrigierenBürgschaftsdokumentHochladenBürge',
      props: true,
      component: () =>
        import(
          './components/buergschaften/aktionen/korrigieren/BuergschaftKorrigierenBuergschaftsdokumentHochladen.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.MANUELL_VERWALTEN,
      },
    },

    {
      path: 'buergschaften/:buergschaftId(\\d+)/korrigieren/zusammenfassung',
      name: 'bürgschaftKorrigierenZusammenfassungBürge',
      props: true,
      component: () =>
        import(
          './components/buergschaften/aktionen/korrigieren/BuergschaftKorrigierenZusammenfassung.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.MANUELL_VERWALTEN,
      },
    },

    {
      path: 'buergschaften/:buergschaftId(\\d+)/korrigieren/erfolg',
      name: 'bürgschaftKorrigierenErfolgBürge',
      props: true,
      component: () =>
        import('./components/buergschaften/aktionen/korrigieren/BuergschaftKorrigierenErfolg.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.MANUELL_VERWALTEN,
      },
    },

    {
      path: 'buergschaften/details/:buergschaftId(\\d+)/:inanspruchnahmeId(\\d+)?',
      name: 'bürgschaftDetailsBürge',
      props: true,
      component: () => import('./components/buergschaften/BuergschaftDetails.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },

    /** BÜRGSCHAFTSTEXTE */
    {
      path: 'buergschaftstexte/liste',
      name: 'bürgschaftstexteListeBürge',
      component: () => import('./components/buergschaftstexte/BuergschaftstexteListe.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaftstexte/auftraggebertext/details/:buergschaftstemplateId([A-Z][A-Z]\\d{4}-\\d{4}-\\d{3})/:aufgabeId(\\d+)?',
      name: 'auftraggebertextDetailsBürge',
      props: true,
      component: () => import('./components/buergschaftstexte/AuftraggebertextDetails.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaftstexte/auftraggebertext/erstpruefung/:buergschaftstemplateId([A-Z][A-Z]\\d{4}-\\d{4}-\\d{3})/:aktion(\\w+)',
      name: 'auftraggebertextPrüfenErstprüfungBürge',
      props: true,
      component: () =>
        import(
          './components/buergschaftstexte/aktionen/pruefen/AuftraggebertextPruefenErstpruefung.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ERSTPRÜFEN,
      },
    },
    {
      path: 'buergschaftstexte/auftraggebertext/zweitpruefung/:buergschaftstemplateId([A-Z][A-Z]\\d{4}-\\d{4}-\\d{3})/:aufgabeId(\\d+)?/:aktion(\\w+)',
      name: 'auftraggebertextPrüfenZweitprüfungBürge',
      props: true,
      component: () =>
        import(
          './components/buergschaftstexte/aktionen/pruefen/AuftraggebertextPruefenZweitpruefung.vue'
        ),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ZWEITPRÜFEN,
      },
    },
    {
      path: 'buergschaftstexte/buergentext/details/:buergschaftstemplateId([A-Z][A-Z]\\d{4}-\\d{4}-\\d{3})',
      name: 'bürgentextDetailsBürge',
      props: true,
      component: () => import('./components/buergschaftstexte/BuergentextDetails.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'buergschaftstexte/oeffentlichertext/details/:buergschaftstemplateId([A-Z][A-Z]\\d{4}-\\d{4}-\\d{3})',
      name: 'öffentlicherTextDetailsBürge',
      props: true,
      component: () => import('./components/buergschaftstexte/OeffentlicherTextDetails.vue'),
      meta: {
        kategorie: [KATEGORIE.BÜRGSCHAFTSTEXTE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },

    /** UNTERNEHMEN */
    {
      path: 'unternehmen/liste',
      name: 'unternehmenListeBuerge',
      component: () => import('./components/unternehmen/UnternehmenListe.vue'),
      meta: {
        kategorie: [KATEGORIE.UNTERNEHMEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'unternehmen/:unternehmenId/uebersicht',
      name: 'unternehmenUebersichtBuerge',
      props: true,
      component: () => import('./components/unternehmen/UnternehmenUebersicht.vue'),
      meta: {
        kategorie: [KATEGORIE.UNTERNEHMEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    /** VERTRÄGE */
    {
      path: 'vertraege/liste',
      name: 'verträgeListeBürge',
      component: () => import('./components/vertraege/VertraegeListe.vue'),
      meta: {
        kategorie: [KATEGORIE.VERTRÄGE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'vertraege/details/:kautionsvertragId/:vertragsnummer',
      name: 'vertragDetailsBürge',
      props: true,
      component: () => import('./components/vertraege/VertragDetails.vue'),
      meta: {
        kategorie: [KATEGORIE.VERTRÄGE],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'vertraege/:kautionsvertragId/:pruefungId(P-\\d{6})/zuordnung-pruefen',
      name: 'vertragZuordnungPrüfenBürge',
      props: true,
      component: () => import('./components/vertraege/VertragZuordnungPruefen.vue'),
      meta: {
        kategorie: [KATEGORIE.VERTRÄGE],
        anzeigeName: ANZEIGENAME.PRÜFEN,
      },
    },
    {
      path: 'vertraege/:kautionsvertragId/:pruefungId(P-\\d{6})/zuordnung-pruefen-abschliessen',
      name: 'vertragZuordnungPrüfenAbschließenBürge',
      props: true,
      component: () => import('./components/vertraege/VertragZuordnungPruefenAbschliessen.vue'),
      meta: {
        kategorie: [KATEGORIE.VERTRÄGE],
        anzeigeName: ANZEIGENAME.PRÜFEN,
      },
    },
    {
      path: 'vertraege/:kautionsvertragId/:pruefungId(P-\\d{6})/zuordnung-pruefen-erfolg',
      name: 'vertragZuordnungPrüfenErfolgBürge',
      props: true,
      component: () => import('./components/vertraege/common/VertragZuordnungPruefenErfolg.vue'),
      meta: {
        kategorie: [KATEGORIE.VERTRÄGE],
        anzeigeName: ANZEIGENAME.PRÜFEN,
      },
    },

    /** NUTZER UND ROLLEN */
    {
      path: 'nutzer/liste/:aktiverTab?',
      name: 'nutzerRollenListeBürge',
      component: () => import('./components/nutzer/NutzerRollenListe.vue'),
      meta: {
        kategorie: [KATEGORIE.NUTZER, KATEGORIE.ROLLEN],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'nutzer/anlegen',
      name: 'nutzerErstellenBürge',
      component: () => import('./components/nutzer/NutzerErstellen.vue'),
      meta: {
        kategorie: [KATEGORIE.NUTZER],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'nutzer/wiederherstellen',
      name: 'nutzerWiederherstellenBürge',
      component: () => import('./components/nutzer/NutzerWiederherstellen.vue'),
      meta: {
        kategorie: [KATEGORIE.NUTZER],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'nutzer/rollen/anlegen',
      name: 'rolleErstellenBürge',
      component: () => import('./components/nutzer/RolleErstellen.vue'),
      meta: {
        kategorie: [KATEGORIE.ROLLEN],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },
    {
      path: 'nutzer/:nutzerId([0-9a-fA-F]{8}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{4}\\b-[0-9a-fA-F]{12})/bearbeiten',
      name: 'nutzerBearbeitenBürge',
      props: true,
      component: () => import('./components/nutzer/NutzerBearbeiten.vue'),
      meta: {
        kategorie: [KATEGORIE.NUTZER],
        anzeigeName: ANZEIGENAME.ANZEIGEN,
      },
    },
    {
      path: 'nutzer/rollen/bearbeiten',
      name: 'rolleBearbeitenBürge',
      component: () => import('./components/nutzer/RolleBearbeiten.vue'),
      meta: {
        kategorie: [KATEGORIE.ROLLEN],
        anzeigeName: ANZEIGENAME.VERWALTEN,
      },
    },

    /** KONTOÜBERSICHT */
    {
      path: 'kontouebersicht',
      name: 'kontoÜbersichtBürge',
      component: () => import('../shared/Nutzer/Components/KontoUebersicht.vue'),
    },

    /** ERROR SCREEN FEHLENDE RECHTE */
    {
      path: 'error',
      name: 'fehlerScreenFehlendeRechteBürge',
      component: () => import('./components/common/FehlerScreenFehlendeRechte.vue'),
    },
  ],
};

export { buergeRoutes };
