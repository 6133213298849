import { Bürgschaftsart as Buergschaftsart } from '@/types/Bürgschaft';
import { Templatetyp } from '@/types/Dokument';
import { ANZEIGENAME, isRechtVorhanden, KATEGORIE } from '@/persistence/RechteStorage';

export function getRouteBuergschaftstexteListe(buergschaftsart: Buergschaftsart): string {
  if (buergschaftsart) {
    switch (buergschaftsart) {
      case Buergschaftsart.ANVORAUSZAHLUNG:
        return 'anVorauszahlungstexteAuftraggeber';
      case Buergschaftsart.AUSFUEHRUNG:
        return 'ausführungstexteAuftraggeber';
      case Buergschaftsart.VERTRAGSERFUELLUNG:
        return 'vertragserfüllungstexteAuftraggeber';
      case Buergschaftsart.MAENGELANSPRUECHE:
        return 'mängelansprüchetexteAuftraggeber';
      default:
        return '';
    }
  } else {
    return '';
  }
}

export function getRouteTemplatetyp(templatetyp: Templatetyp): string {
  if (templatetyp) {
    switch (templatetyp) {
      case Templatetyp.OET:
        return 'öffentlicherTextHinzufügenAuftraggeber';
      case Templatetyp.AGT:
        return 'auftraggebertextHochladenAuftraggeber';
      case Templatetyp.BGT:
        return 'bürgenTextHinzufügenAuftraggeber';
      default:
        return '';
    }
  } else {
    return '';
  }
}

export function getRouteHierarchieAuftraggeber() {
  if (isRechtVorhanden([KATEGORIE.AUFGABEN], ANZEIGENAME.ANZEIGEN)) {
    return 'aufgabenListeAuftraggeber';
  }
  if (isRechtVorhanden([KATEGORIE.BÜRGSCHAFTEN], ANZEIGENAME.ANZEIGEN)) {
    return 'buergschaftenListeAuftraggeber';
  }
  if (isRechtVorhanden([KATEGORIE.BÜRGSCHAFTEN], ANZEIGENAME.ANZEIGEN)) {
    return 'buergschaftsauftraegeListeAuftraggeber';
  }
  if (isRechtVorhanden([KATEGORIE.PROJEKTE], ANZEIGENAME.ANZEIGEN)) {
    return 'projekteListeAuftraggeber';
  }
  if (isRechtVorhanden([KATEGORIE.BÜRGSCHAFTSTEXTE], ANZEIGENAME.ANZEIGEN)) {
    return 'bürgschaftstexteKachelÜbersichtAuftraggeber';
  }
  if (isRechtVorhanden([KATEGORIE.UNTERNEHMEN], ANZEIGENAME.ANZEIGEN)) {
    return 'unternehmenKachelÜbersichtAuftraggeber';
  }
  if (isRechtVorhanden([KATEGORIE.VERTRÄGE], ANZEIGENAME.ANZEIGEN)) {
    return 'verträgeListeAuftraggeber';
  }
  if (isRechtVorhanden([KATEGORIE.ROLLEN, KATEGORIE.NUTZER], ANZEIGENAME.ANZEIGEN)) {
    return 'nutzerRollenListeAuftraggeber';
  }
  return 'kontoÜbersichtAuftraggeber';
}
