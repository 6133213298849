import type { Land } from '../types/Partner';

export interface LänderStorage {
  länder: Land[];
}

export function createEmptyLänderStorage(): LänderStorage {
  return {
    länder: [],
  };
}

export function ladeLaenderStorage(): LänderStorage {
  if (sessionStorage.getItem('länderStorage')) {
    return JSON.parse(sessionStorage.getItem('länderStorage'));
  }
  return createEmptyLänderStorage();
}

export function speichereLänderStorage(länderStorage: LänderStorage) {
  sessionStorage.setItem('länderStorage', JSON.stringify(länderStorage));
}
