import conf from '@/shared/config/useConfig';

/**
 * Baut die URL für einen Backendservice zusammen. Die URL ist relativ zur aktuellen location.
 * Für die lokale Entwicklungsumgebung gibt es außerdem eine Fallback-URL
 *
 * @param serviceName Service-Name des anzusprechenden Backend-Service
 */
export function buildUrlForServiceName(serviceName: string): string {
  const allowedDomains = conf.allowedDomains as string;
  const { hostname } = window.location;

  if (allowedDomains && allowedDomains.length > 0) {
    const domainToUse = allowedDomains
      .split(',')
      .map((d) => d.trim())
      .find((d) => hostname.endsWith(d));

    if (domainToUse) {
      // Der Teil ist etwas hässlich. Auf PROD läuft die GUI direkt auf der Domäne, das Backend aber auf einer
      // Stage-Subdomain. Z.B. app.trustlog.de -> bsp-nutzerverwaltung.prod.trustlog.de
      // In den Teststages ist es hingegen konsistent. Z.B. view.test.trustlog.de -> bsp-nutzerverwaltung.test.trustlog.de

      const isProduction = conf.stageName.trim() === 'production';

      if (isProduction) {
        // Noch eine Sonderlocke: Bisher nutzte die app.bsp-gmbh.de immer Backend und Nutzerverwaltung aus
        // test-bsp-project.net. Das soll so bleiben, um hier nichts kaputt zu machen. Theoretisch müsste das Backend
        // auch unter bsp-gmbh.de laufen, aber das will ich gerade nicht live testen müssen.
        const domainToUseProd = domainToUse.replace('bsp-gmbh.de', 'test-bsp-project.net');

        return `https://${serviceName}.prod.${domainToUseProd}`;
      }
      return `https://${serviceName}.${domainToUse}`;
    }
    throw new Error(
      `Hostname ${hostname} ist nicht in der Liste der erlaubten Domains: ${allowedDomains}`,
    );
  } else {
    // Dass allowedDomains nicht gesetzt ist, gibt es nur auf der lokalen Entwicklungsumgebung
    return `http://${serviceName}`;
  }
}

/** Gibt die eigene URL zurück */
export function buildSelfUrl(): string {
  if (window.location.hostname === 'localhost') {
    return `${window.location.protocol}//${window.location.hostname}:${window.location.port}`;
  }
  return `${window.location.protocol}//${window.location.hostname}`;
}
