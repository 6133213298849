import type { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { createRouter, createWebHashHistory } from 'vue-router';

import { keycloakApi } from '@/connection/keycloak-api';
import {
  ladeRechteAusPartnerServiceInDenRechteStorage,
  setRouteNamen,
} from '@/shared/navigation/routerGuardUtilities/storageUtilities';
import { useRouteStore } from '@/shared/navigation/useRouteStore/useRouteStore';
import { ladeStartseiteWennKeineDomaenenRoute } from '@/shared/navigation/routerGuardUtilities/findeRichtigeStartSeite';
import { pruefeBerechtigung } from '@/shared/navigation/routerGuardUtilities/pruefeBerechtigungen';
import { countdownService } from '@/service/CountdownService';

import { auftraggeberRoutes } from '@/auftraggeber/routes';
import { bspMitarbeiterRoutes as trustlogRoutes } from './bsp/routes';
import { buergeRoutes } from './buerge/routes';

const router = createRouter({
  history: createWebHashHistory(''),
  routes: [
    {
      name: 'RootView',
      path: '/',
      component: () => import('@/shared/RootView/RootView.vue'),
    },
    auftraggeberRoutes,
    trustlogRoutes,
    buergeRoutes,
  ],
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (savedPosition !== null && savedPosition !== undefined) {
          resolve(savedPosition);
        }

        document.querySelector('.content-wrapper').scrollTo({
          left: 0,
          top: 0,
        });
        resolve();
      }, 250);
    });
  },
});

router.beforeEach(
  async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
    /**
     * Durch Keycloak und den Token refresh kommen wir initial auf eine URL die keine bekannte
     * Route matcht, wenn das der Fall, bereinigen wir einmal die URL und ersetzen die vorherige
     * Navigation in der History.
     */
    const index = to.path.indexOf('&state=');
    if (index !== -1) {
      return next({ path: to.path.slice(0, index), replace: true });
    }

    /**
     * Eine kleine Optimierung:
     *  Da wir nach einem Seitenwechsel oftmals gleich mehrere Services anfragen, schauen wir hier
     *  erst mal, ob wir nicht ein neues Token brauchen. Das erspart uns unnötige HTTP-Requests mit
     *  401 Fehler.
     */
    await keycloakApi.refreshToken(3);

    await ladeRechteAusPartnerServiceInDenRechteStorage();

    const startSeite = ladeStartseiteWennKeineDomaenenRoute(to);
    const fehlerSeite = pruefeBerechtigung(to);
    // baue kleine Routen Historie im Store auf
    useRouteStore().setzeAktuelleRoute({
      name: to.name.toString(),
      params: to.params,
    });

    if (startSeite === null && fehlerSeite === null) {
      return next();
    }

    if (startSeite !== null && fehlerSeite === null) {
      return next({ name: startSeite, replace: true });
    }

    return next({ name: fehlerSeite, replace: true });
  },
);

router.afterEach((to, from) => {
  setRouteNamen(to, from);
  countdownService.reset();
});

export default router;
