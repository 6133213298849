import type { CustomToken } from '../connection/keycloak-api';
import { keycloakApi } from '../connection/keycloak-api';

import type { Nutzer } from '../types/Nutzer';
import { createEmptyNutzer } from '../types/Nutzer';

function extractOrganisation(token: CustomToken) {
  return token.group[0].toString().substr(1, 6);
}

// private Funktion. Mappt JWT auf Nutzer-Objekt
function mapNutzer(token: CustomToken): Nutzer {
  const nutzer = createEmptyNutzer();

  nutzer.organisation = extractOrganisation(token);
  nutzer.vorname = token.given_name;
  nutzer.nachname = token.family_name;
  nutzer.telefonnummer = null; // Diese Information fehlt im JWT
  nutzer.email = token.email;
  nutzer.unternehmenSichtbeschränkt = token.unternehmenSichtbeschränkt;
  nutzer.erhaltNutzerEMails = token.erhaltNutzerEMails;

  return nutzer;
}

/**
 * Ruft alle Informationen zum Nutzer ab. Präferierte Funktion, um Namen, Rolle und Organisation des Nutzers zu holen.
 *
 * ACHTUNG: Hier fehlt die Telefonnummer. Wir brauchen sie nicht unbedingt überall. Bei Bedarf via keycloakApi.ladeNutzer() holen.
 *          Das Token hat eine Gültigkeit von 5 min, ist also ziemlich aktuell. Wenn es hyper-aktuell sein muss
 *          (z.B. bei einer Bearbeiten-Maske), dann ebenfalls keycloakApi.ladeNutzer() verwenden.
 */
export function getNutzer(): Nutzer {
  return mapNutzer(keycloakApi.getTokenParsed());
}

/** Ruft nur die Nutzer-ID des Nutzers ab */
export function getEigeneNutzerId(): string {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return keycloakApi.getTokenParsed()?.sub;
}

/** Ruft nur die Organisation des Nutzers ab */
export function getEigeneOrganisation(): string {
  const token = keycloakApi.getTokenParsed();
  return extractOrganisation(token);
}

/** Ruft nur den Rollennamen des Nutzers ab */
export function getRolleName(): string {
  const token = keycloakApi.getTokenParsed();
  return token.group[0].substr(2 + getEigeneOrganisation().length);
}

/** Ruft nur die Information ab, ob der Nutzer SSO-Nutzer ist */
export function isSsoNutzer(): boolean {
  const token = keycloakApi.getTokenParsed();
  return token.sso;
}

/** Ruft nur die Information ab, ob der Nutzer die Admin-Rolle hat */
export function isAdmin(): boolean {
  return getRolleName() === 'Administrator:in';
}
