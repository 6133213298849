import type { RouteLocationNormalized } from 'vue-router';

import { isRechtVorhanden } from '@/persistence/RechteStorage';

export const pruefeBerechtigung = (to: RouteLocationNormalized): string | null => {
  const sindRechteDatenVorhanden = to.matched.some((r) => r.meta.kategorie && r.meta.anzeigeName);
  let fehlerSeite: string | null = null;

  if (sindRechteDatenVorhanden) {
    const hatNutzerPassendeRechte = to.matched.some((r) =>
      isRechtVorhanden(r.meta.kategorie as string[], r.meta.anzeigeName as string),
    );

    if (!hatNutzerPassendeRechte) {
      if (to.matched[0].name === 'auftraggeber') {
        fehlerSeite = 'fehlerScreenFehlendeRechteAuftraggeber';
      }

      if (to.matched[0].name === 'buerge') {
        fehlerSeite = 'fehlerScreenFehlendeRechteBürge';
      }

      if (to.matched[0].name === 'bspGmbh') {
        fehlerSeite = 'fehlerScreenFehlendeRechteTrustlog';
      }
    }
  }

  return fehlerSeite;
};
