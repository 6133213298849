import type { Buerge } from '@/shared/Buergen/types';
import type { Nutzer } from '../types/Nutzer';

export interface NavigationStorage {
  nutzer: Nutzer;
  eigenesUnternehmen: Buerge;
  vollerName: string;
  routeNamen: string[];
}

export function createEmptyNavigationStorage(): NavigationStorage {
  return {
    nutzer: null,
    eigenesUnternehmen: undefined,
    vollerName: '',
    routeNamen: [],
  };
}

export function ladeNavigationStorage(): NavigationStorage {
  if (sessionStorage.getItem('navigationStorage')) {
    return JSON.parse(sessionStorage.getItem('navigationStorage'));
  }
  return createEmptyNavigationStorage();
}

export function speichereNavigationStorage(navigationStorage: NavigationStorage) {
  sessionStorage.setItem('navigationStorage', JSON.stringify(navigationStorage));
}

export function getRouteFromName(): string {
  const navigationStorage = ladeNavigationStorage();
  return navigationStorage.routeNamen[1];
}
