import { HttpService } from '@/shared/api/services/HttpService';
import type { Aufgabe } from '@/types/Aufgabe';
import type {
  AufgabeFilter,
  AufgabeFilterResponse,
} from '@/connection/filter-helper/filter-helper-aufgaben';
import { buildUrlForServiceName } from '@/connection/url-retriever';
import conf from '@/shared/config/useConfig';

export class SharedAufgabenService extends HttpService {
  protected aufgabenServiceBaseUrl = `${buildUrlForServiceName(conf.aufgabenserviceName)}/aufgabenservice/api/private/v1/aufgaben`;

  holeAufgabe(id: string): Promise<Aufgabe> {
    return this.httpClient.get(`${this.aufgabenServiceBaseUrl}/${id}`);
  }

  holeAufgabenMitPaginierungUndFilterung(
    seite: number,
    seitengroesse: number,
    property: string,
    sortierArt: string,
    aufgabeFilter: AufgabeFilter,
  ): Promise<AufgabeFilterResponse> {
    return this.httpClient.post(`${this.aufgabenServiceBaseUrl}/filterung`, {
      params: { page: seite, size: seitengroesse, sort: property, direction: sortierArt },
      data: aufgabeFilter,
    });
  }
}
