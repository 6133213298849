import type { App } from 'vue';
import { browserTracingIntegration, init, replayIntegration } from '@sentry/vue';
import { getActivePinia } from 'pinia';

import router from '@/router';
import { useNutzerStore } from '@/shared/Nutzer/useNutzerStore';

const getEnvironment = () => {
  const stage = import.meta.env.VITE_STAGE;
  if (stage !== undefined && stage !== '') {
    return stage;
  }
  return 'localhost';
};

export const initSentry = (app: App) => {
  if (import.meta.env.DEV) {
    // Wenn in Development, dann wird Sentry Client nicht initialisiert
    return;
  }

  init({
    app,
    dsn: 'https://e904d3fd756a431a8d4a3e93750f2991@o4505080782585856.ingest.sentry.io/4505289745760256',
    environment: getEnvironment(),
    integrations: [browserTracingIntegration({ router }), replayIntegration()],
    normalizeDepth: 6,
    // Performance Monitoring
    tracesSampleRate: Number.parseFloat(import.meta.env.VITE_SENTRY_TRACE_RATE) || undefined, // 0.25, // Prozent der Transactions die aufgezeichnet werden
    // Session Replay
    replaysSessionSampleRate:
      Number.parseFloat(import.meta.env.VITE_SENTRY_REPLAY_RATE) || undefined, // Prozent der Sessions die aufgezeichnet werden 0.1
    replaysOnErrorSampleRate:
      Number.parseFloat(import.meta.env.VITE_SENTRY_REPLAY_ON_ERROR_RATE) || undefined, // Prozent der Sessions die im Fehlerfall aufgezeichnet werden

    // Verbinde Client Code mit Sentry Release und SourceMaps
    release: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release || undefined,
    dist: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.dist || undefined,

    // füge hashed Nutzer ID zu Sentry Events hinzu
    beforeSend(event) {
      const shallowCopy = { ...event };

      // Teste, ob aktive Pinia Instanz vorhanden, ansonsten kann es zu Exceptions beim
      // Versuch useNutzerStore zu benutzen kommen.
      if (getActivePinia() !== undefined) {
        shallowCopy.user = {
          id: useNutzerStore().nutzerIdHash,
        };
      }

      return shallowCopy;
    },
  });
};
