const cssPrefixes = ['Webkit', 'O', 'Moz', 'ms'];

/**
 * Returns the vendor-prefixed version of a style property name.
 *
 * @param {string} name
 * @return string
 *
 * @example
 *     getVendorProp('flex') => 'WebkitFlex'
 */
function getVendorProp(name: string) {
  const { style } = document.body;
  if (name in style) {
    return name;
  }

  const capName = name.charAt(0).toUpperCase() + name.slice(1);
  let vendorName;

  for (let i = cssPrefixes.length; i >= 0; i -= 1) {
    vendorName = cssPrefixes[i] + capName;
    if (vendorName in style) {
      return vendorName;
    }
  }

  return name;
}

/**
 * @param {string} string
 */
function camelCase(string: string) {
  return string
    .replace(/^-ms-/, 'ms-')

    .replace(/-([\da-z])/gi, (match, letter) => letter.toUpperCase());
}

/**
 * (Internal) Applies css properties to an element, similar to the jQuery
 * css method.
 *
 * While this helper does assist with vendor prefixed property names, it
 * does not perform any manipulation of values prior to setting styles.
 */
const setCSS = (() => {
  const cssProps = {};

  function getStyleProp(/** @type string */ name: string) {
    const camelCaseName = camelCase(name);
    // @ts-expect-error Typing unklar
    if (cssProps[camelCaseName] === undefined) {
      // @ts-expect-error Typing unklar
      cssProps[camelCaseName] = getVendorProp(name);
    }

    // @ts-expect-error Typing unklar
    return cssProps[name];
  }

  function applyCss(
    /** @type HTMLElement */ element: HTMLElement,
    /** @type string */ prop: string,
    /** @type string */ value: string,
  ) {
    const styleProp = getStyleProp(prop);
    element.style.setProperty(styleProp, value);
  }

  // @ts-expect-error Typing unklar
  return function tempSetCSS(/** @type HTMLElement */ element, /** @type any */ properties) {
    // Disabled, da das Umschreiben auf den rest Parameter ohne vorhandene Tests als zu Risikoreich angesehen.
    // eslint-disable-next-line prefer-rest-params
    const args = arguments;

    if (args.length === 2) {
      Object.keys(properties).forEach((prop) => {
        const value = properties[prop];
        if (value !== undefined && Object.prototype.hasOwnProperty.call(properties, prop)) {
          applyCss(element, prop, value);
        }
      });
    } else {
      applyCss(element, args[1], args[2]);
    }
  };
})();

export default setCSS;
