import type { Recht, Rolle } from '../types/Nutzer';

export const KATEGORIE = {
  AUFGABEN: 'Aufgaben',
  MITTEILUNGEN: 'Mitteilungen',
  ANTRÄGE: 'Anträge',
  BÜRGSCHAFTEN: 'Bürgschaften',
  PROJEKTE: 'Projekte',
  BÜRGSCHAFTSTEXTE: 'Bürgschaftstexte/Templates',
  UNTERNEHMEN: 'Unternehmen',
  TOCHTERUNTERNEHMEN: 'Tochterunternehmen',
  VERTRÄGE: 'Verträge',
  NUTZER: 'Nutzer',
  ROLLEN: 'Rollen',
  GEVOS: 'Gevos',
};

export const ANZEIGENAME = {
  // AUFTRAGGEBER
  ANZEIGEN: 'anzeigen',
  PRÜFEN: 'prüfen',
  ENTHAFTEN: 'zurückgeben',
  TEILENTHAFTEN: 'teilenthaften',
  IN_ANSPRUCH_NEHMEN: 'in Anspruch nehmen',
  AUSWÄHLEN: 'auswählen',
  BEARBEITEN: 'bearbeiten',
  VERWALTEN: 'verwalten',
  LÖSCHEN: 'löschen',
  ANLEGEN: 'anlegen',
  IMPORTIEREN: 'importieren',
  EINREICHEN: 'einreichen',
  ABTRETEN: 'abtreten',

  // BÜRGE
  BÜRGSCHAFTSDOKUMENT_HOCHLADEN: 'Bürgschaftsdokument hochladen',
  ERSTPRÜFEN: 'erstprüfen',
  ZWEITPRÜFEN: 'zweitprüfen',
  ENTSCHEIDUNGDOKUMENTATION_HERUNTERLADEN: 'Entscheidungsdokumentation herunterladen',
  INANSPRUCHNAHMEDOKUMENTATION_HERUNTERLADEN: 'Inanspruchnahmedokumentation herunterladen',
  BESCHLUSSDOKUMENTATION_HERUNTERLADEN: 'Beschlussdokumentation herunterladen',
  MANUELL_VERWALTEN: 'manuell verwalten',

  // BSP
  FERTIGSTELLEN: 'fertigstellen',
  SPEICHERN: 'speichern',
  HOCHLADEN: 'hochladen',
  ZUORDNEN: 'zuordnen',
};

export interface RechteStorage {
  eigeneRechte: Recht[];
  alleRechte: Recht[];
}

export function createEmptyRechteStorage(): RechteStorage {
  return {
    eigeneRechte: [],
    alleRechte: [],
  };
}

export function ladeRechteStorage(): RechteStorage {
  return sessionStorage.getItem('rechteStorage')
    ? JSON.parse(sessionStorage.getItem('rechteStorage'))
    : createEmptyRechteStorage();
}

function compareRechtNachSortierung(recht1: Recht, recht2: Recht): number {
  // "anzeigen" ist eine Sonderlocke und muss immer an erster Stelle stehen
  if (recht1.anzeigename === ANZEIGENAME.ANZEIGEN) {
    return -1;
  }
  return recht2.anzeigename === ANZEIGENAME.ANZEIGEN
    ? 1
    : recht1.anzeigename.localeCompare(recht2.anzeigename);
}

function sortiereRechte(rechteStorage: RechteStorage) {
  // Die "eigeneRechte" werden in keiner Anzeigelogik verwendet, deswegen werden hier nur "alleRechte" sortiert.
  rechteStorage.alleRechte?.sort((r1, r2) => compareRechtNachSortierung(r1, r2));
}

export function speichereRechteStorage(rechteStorage: RechteStorage) {
  sortiereRechte(rechteStorage);
  sessionStorage.setItem('rechteStorage', JSON.stringify(rechteStorage));
}

function compareRolleNachSortierung(rolle1: Rolle, rolle2: Rolle): number {
  // Eine disabled-Rolle soll a letzter Stele stehen
  if (rolle1.immutable) {
    return 1;
  }
  return rolle2.immutable ? -1 : rolle1.name.localeCompare(rolle2.name);
}

export function sortiereRollen(rollenListe: Rolle[]) {
  return rollenListe.sort((r1, r2) => compareRolleNachSortierung(r1, r2));
}

/**
 * Prüft, ob der Benutzer ein Recht hat - allerdings nicht direkt gegen das Recht (z.B. AG_BUERGSCHAFT_TEILENTHAFTEN),
 * sondern etwas verkopft gegen die Struktur aus der Rollen & Rechte GUI: kategorie und anzeigeName.
 *
 * Dass sämtliche Rechtesteuerung auf dieser Funktion basiert ist uncool. Dadurch gibt es eine unnötige Abhängigkeit auf den PartnerService (Auswirkung auf Verfügbarkeit; Fehleranfälligkeit; Sicherheit).
 * Die Rechte, die ein Nutzer hat, stehen im Token, die der Keycloak ausstellt und sind über die Keycloak-API zugreifbar.
 * Die aus dem PartnerService gelieferten Rechte sollen nur dort verwendet werden, wo zwingend der Anzeige-Name benötigt wird (wie z.B. in der Nutzer- und Rollenverwaltung)
 *
 * Bei dem aktuellen Konzept wird außerdem ein eindeutiger Rechte-Schlüssel wie BG_TEMPLATE_ABRUFEN aufgesplittet in Kategorie und Anzeigename und muss anschließend wieder zusammengebastelt werden:
 * meta: {
 *       kategorie: [KATEGORIE.TEMPLATE],
 *       anzeigeName: ANZEIGENAME.ABRUFEN,
 *     },
 * das ist fehleranfällig, da man unzulässige Kombinationen erzeugen kann wie z.B. KATEGORIE.TEMPLATE + ANZEIGENAME.TEILENTHAFTEN.
 * Sähe die Implementierung z.B. so aus: meta: { rolesAllowed: [RECHT.AG_BUERGSCHAFT_TEILENTHAFTEN] }, wäre so ein Fehler ausgeschlossen.
 *
 * Einschätzung:
 * - Verfügbarkeit: der PartnerService wird aktuell in so ziemlich jeder View verwendet, daher ist hier eh schon eine unverzichtbare Abhängigkeit gegeben.
 * - Fehleranfälligkeit und Sicherheit: Wichtiger als die Absicherung im Frontend ist die Absicherung um Backend und hier werden die Rechte ordnungsgemäß verwendet
 * (ohne Abstraktionen/Komplexität in einer @RolesAllowed-Annotation)
 *
 * -> aktuell kein Handlungsbedarf, muss aber beobachtet werden
 */
export function isRechtVorhanden(kategorie: string[], anzeigeName: string): boolean {
  const rechteStorage: RechteStorage = ladeRechteStorage();
  return (
    rechteStorage.eigeneRechte.filter(
      (r) => r.anzeigename === anzeigeName && kategorie.includes(r.kategorie),
    ).length > 0
  );
}

export function isRechteStorageVorhanden(): boolean {
  return (
    // Es gibt mindestens das Recht *_BSP_BENUTZEN
    sessionStorage.getItem('rechteStorage') !== null && ladeRechteStorage().eigeneRechte.length > 0
  );
}

export const iconNamen: string[] = ['default', 'lock', 'user', 'watcher', 'einhorn', 'helm'];
