import { HttpService } from '@/shared/api/services/HttpService';
import { buildUrlForServiceName } from '@/connection/url-retriever';
import conf from '@/shared/config/useConfig';
import type { ScanErgebnis } from '@/types/DokumentUpload';
import { configForMultipartFormData } from '@/connection/base';

export class SharedVirenScannerService extends HttpService {
  protected virenServiceBaseUrl = `${buildUrlForServiceName(
    conf.virenscannerName,
  )}/virenscanner/api/private/v1`;

  async scan(file: File): Promise<ScanErgebnis> {
    const formData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(`${this.virenServiceBaseUrl}/scan`, {
      data: formData,
      config: configForMultipartFormData,
    });
  }
}
