/**
 * Basis-Infrastuktur für Backend-Calls. Nicht zur direkten Verwendung (verwende die spezifischen ${SERVICE}-api.ts
 */
import type { AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';
import axios from 'axios';

import { countdownService } from '@/service/CountdownService';
import { keycloakApi } from './keycloak-api';
import ProgressBar from '../common/progressbar/progressbar';
import '../common/progressbar/progressbar.css';
import { buildSelfUrl } from './url-retriever';
//
/**
 * // TODO: performanteres Laden der Nicht prüfbaren Templates -
 * dann wieder auf 2000 bzw. noch besser: richtig lösen
 * MLP-3923 (JIRA) - Refactoring Service-Aufruf: dokumentserviceApi.findNichtPrüfbareTemplateIds()
 * MLP-3660 (JIRA) - Etwas mit Timeouts machen
 * Aktueller Hintergrund: Liste der Aufgaben TL lädt nicht schnell genug und läuft in Timeout
 */
const TIMEOUT_DEFAULT = 60_000;
/**
 *     Vorgabe laut ursprünglichem Architekturkonzept der Ausschreibung: Antwortzeit des Backends ist 200ms
 *     Status quo: Timeouts werden nach 3 Sekunden erreicht => 3000ms ist schon ein extremer Puffer
 *     Timeouts können technisch durch lange Antwortzeiten oder durch schlechte Internetverbindungen der Nutzer hervorgerufen werden
 *     Vor ca. 1 Jahr hatten wir starke Performance-Probleme, die dann verbessert wurden
 *     Einzelne Requests können aber den Timeout explizit überschreiben.
 */
const TIMEOUT_FILE_TRANSFER = 60_000; // Dateiübertragungen dürfen schon mal länger dauern

/**
 * Erzeugt einen Client für unsere (einheitlich implementierten) Spring Boot REST-Services.
 * Sollte nur nach gründlicher Prüfung für andere REST-Services als unsere eigenen verwendet werden.
 *
 * ACHTUNG: Es gibt einen Design-Flaw: Jeder API-Client nutzt also seine eigene Instanz. Klingt erst mal ganz nett.
 * Problem: Wenn das Token abgelaufen ist, holen wir uns ein Neues - siehe handleInvalidToken und isRefreshing.
 * Da wir aber oftmals bei einem Seitenwechsel gleich aus mehreren Services Daten laden, und wir pro Service einen
 * Client haben, holen wir uns in diesem Fall gleich mehrfach ein neues Token.
 * Es gibt für dieses Problem einen Workaround im Router: Vor einem Seitenwechsel holen wir uns bei Bedarf ein neues
 * Token, noch bevor wir die Seite betreten. Das macht sowieso Sinn (siehe Kommentar im Router), um gar nicht erst
 * Requests abzusenden, wenn das Token abgelaufen ist und erst hinterher festzustellen, dass es abgelaufen ist.
 */
function createApiClient(baseURL: string): AxiosInstance {
  const client = axios.create({
    baseURL,
    headers: {
      'Access-Control-Allow-Origin': buildSelfUrl(),
    },
    timeout: TIMEOUT_DEFAULT,
  });

  let isRefreshing = false;

  /**
   * Setzt das aktuell gültige JWT am Header (Authorization: Bearer $TOKEN).
   * wir setzen es nicht einmalig via client.defaults.headers.Authorization, weil wir ja immer das aktuellste Token haben wollen
   */
  const setToken = (config: InternalAxiosRequestConfig) => {
    if (config.headers && keycloakApi.getToken()) {
      config.headers.Authorization = `Bearer ${keycloakApi.getToken()}`;
    }
  };

  /**
   * Wiederholt einen fehlgeschlagenen Request. Holt sich aber vorher ein neues Token über die Keycloak-API
   */
  const handleInvalidToken = (originalRequest: any): Promise<unknown> | void => {
    if (!isRefreshing) {
      // eslint-disable-next-line no-underscore-dangle
      originalRequest._retry = true;
      isRefreshing = true;

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          await keycloakApi.refreshToken(60); // die minValidity ist ja hier egal, da das Token ja bereits invalide ist
          originalRequest.headers.Authorization = `Bearer ${keycloakApi.getToken()}`;
          resolve(client(originalRequest));
        } catch (err) {
          reject(err);
        } finally {
          isRefreshing = false;
        }
      });
    }
    return Promise.resolve();
  };

  const showProgressBar = () => {
    if (!ProgressBar.isRendered()) {
      ProgressBar.start();
    } else {
      ProgressBar.render(false);
    }
  };

  const hideProgressBar = () => {
    ProgressBar.done(false);
  };

  const errorHideProgressBar = () => {
    ProgressBar.done(true);
  };

  const resetSessionTimer = () => {
    countdownService.reset();
  };

  client.interceptors.request.use(
    (config) => {
      setToken(config);
      showProgressBar();
      return config;
    },
    async (error) => {
      errorHideProgressBar();
      return Promise.reject(error);
    },
  );

  client.interceptors.response.use(
    (response) => {
      hideProgressBar();
      resetSessionTimer();
      return response;
    },
    async (error) => {
      errorHideProgressBar();
      const originalRequest = error.config;
      // eslint-disable-next-line no-underscore-dangle
      if (error?.response?.status === 401 && !originalRequest._retry) {
        // Wir gehen davon aus, dass der 401 von einem abgelaufenen Token herrührt, daher versuchen wir es zu refreshen
        return handleInvalidToken(originalRequest);
      }
      return Promise.reject(error);
    },
  );

  return client;
}

/**
 * Axios Config-Objekt für einen typischen POST eines JSON-Objekts
 */
const configForJsonPost: AxiosRequestConfig = {
  headers: {
    'content-type': 'application/json',
  },
};

const configForMultipartFormData: AxiosRequestConfig = {
  headers: {
    'content-type': 'multipart/form-data',
  },
  timeout: TIMEOUT_FILE_TRANSFER,
};

export const configForMultipartFormDataAndDownload: AxiosRequestConfig = {
  headers: {
    'content-type': 'multipart/form-data',
  },
  responseType: 'blob',
  timeout: TIMEOUT_FILE_TRANSFER,
};

const configForDownload: AxiosRequestConfig = {
  responseType: 'blob',
  timeout: TIMEOUT_FILE_TRANSFER,
};

const configForJsonPostDownload: AxiosRequestConfig = {
  headers: {
    'content-type': 'application/json',
  },
  responseType: 'blob',
  timeout: TIMEOUT_FILE_TRANSFER,
};

function toJsonBlob(obj: object): Blob {
  const json = JSON.stringify(obj);
  return new Blob([json], {
    type: 'application/json',
  });
}

/**
 * Wrapper für alle Abrufe von Paging und Sorting und Filterung REST-Endpoints
 */
interface Page<T> {
  content: T[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: { sorted: boolean; unsorted: boolean; empty: boolean };
    unpaged: boolean;
  };
  size: number;
  sort: { sorted: boolean; unsorted: boolean; empty: boolean };
  totalElements: number;
  totalPages: number;
}

/**
 * @deprecated Sollte nicht mehr verwendet werden.
 * @param date
 * @param format
 */
function formatDate(date: Date, format: string): string {
  const map = {
    mm: (date.getMonth() + 1).toString().padStart(2, '0'),
    dd: date.getDate().toString().padStart(2, '0'),
    yyyy: date.getFullYear(),
  };

  // @ts-expect-error Utility ist sowieso deprecated
  return format.replace(/mm|dd|yyyy/gi, (matched) => map[matched]);
}

function triggerFakeDownload(data: BlobPart, fileName: string): void {
  const fileURL = window.URL.createObjectURL(new Blob([data]));
  const fileLink = document.createElement('a');

  fileLink.href = fileURL;
  fileLink.setAttribute('download', fileName);
  document.body.appendChild(fileLink);

  fileLink.click();
}

/**
 * Encoded die Query-Parameter für eine URL.
 * source: Die URL. Diese muss Platzhalter für die einzelnen Parameter enthalten in der Form ( {0}, {1} usw.)
 * params: Die zu encodenen Parameter als List.
 */
function encodeUrl(source: string, params: string[]): string {
  params.forEach((p) => {
    // eslint-disable-next-line no-param-reassign
    source = source.replace(new RegExp(`\\{${params.indexOf(p)}\\}`, 'g'), encodeURIComponent(p));
  });
  return source;
}

export {
  TIMEOUT_DEFAULT,
  TIMEOUT_FILE_TRANSFER,
  createApiClient,
  configForJsonPost,
  configForMultipartFormData,
  configForDownload,
  configForJsonPostDownload,
  toJsonBlob,
  type Page,
  formatDate,
  triggerFakeDownload,
  encodeUrl,
};
